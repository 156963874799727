export interface ILocaleItem {
    'zh-CN': string
    'zh-TW': string
    'en': string
    'ja': string
    'ko': string
    'vi': string
}

const locales0 = {
    'homepage': {
        'en': 'Homepage',
        'zh-CN': '主页',
        'zh-TW': '主頁',
        'ja': 'ホームページ',
        'ko': '홈페이지',
        'vi': 'Trang chủ',
    },
    'overview': {
        'en': 'Overview',
        'zh-CN': '概览',
        'zh-TW': '概覽',
        'ja': '概要',
        'ko': '개요',
        'vi': 'Tổng quan',
    },
    'user': {
        'en': 'User',
        'zh-CN': '用户',
        'zh-TW': '用戶',
        'ja': 'ユーザー',
        'ko': '사용자',
        'vi': 'Người dùng',
    },
    'creator': {
        'en': 'Creator',
        'zh-CN': '创建者',
        'zh-TW': '創建者',
        'ja': 'クリエーター',
        'ko': '제작자',
        'vi': 'Tác giả',
    },
    'created_at': {
        'en': 'Created At',
        'zh-CN': '创建时间',
        'zh-TW': '創建時間',
        'ja': '作成日時',
        'ko': '생성 날짜',
        'vi': 'Ngày tạo',
    },
    'build_at': {
        'en': 'Build At',
        'zh-CN': '编译时间',
        'zh-TW': '編譯時間',
        'ja': 'ビルド日時',
        'ko': '빌드 날짜',
        'vi': 'Ngày xây dựng',
    },
    'user group': {
        'en': 'User Group',
        'zh-CN': '用户组',
        'zh-TW': '用戶組',
        'ja': 'ユーザーグループ',
        'ko': '사용자 그룹',
        'vi': 'Nhóm người dùng',
    },
    'kube_config': {
        'en': 'Kubernetes Configuration',
        'zh-CN': 'Kubernetes Configuration',
        'zh-TW': 'Kubernetes Configuration',
        'ja': 'Kubernetes Configuration',
        'ko': 'Kubernetes Configuration',
        'vi': 'Kubernetes Configuration',
    },
    'config': {
        'en': 'Configuration',
        'zh-CN': '配置',
        'zh-TW': '配置',
        'ja': '構成',
        'ko': '구성',
        'vi': 'Cấu hình',
    },
    'role': {
        'en': 'Role',
        'zh-CN': '角色',
        'zh-TW': '角色',
        'ja': 'ロール',
        'ko': '역할',
        'vi': 'Vai trò',
    },
    'developer': {
        'en': 'Developer',
        'zh-CN': '开发者',
        'zh-TW': '開發者',
        'ja': '開発者',
        'ko': '개발자',
        'vi': 'Nhà phát triển',
    },
    'guest': {
        'en': 'Guest',
        'zh-CN': '访客',
        'zh-TW': '訪客',
        'ja': 'ゲスト',
        'ko': '게스트',
        'vi': 'Khách',
    },
    'admin': {
        'en': 'Admin',
        'zh-CN': '管理员',
        'zh-TW': '管理者',
        'ja': '管理者',
        'ko': '관리자',
        'vi': 'Quản trị viên',
    },
    'create': {
        'en': 'Create',
        'zh-CN': '创建',
        'zh-TW': '創建',
        'ja': '作成',
        'ko': '생성',
        'vi': 'Tạo',
    },
    'submit': {
        'en': 'Submit',
        'zh-CN': '提交',
        'zh-TW': '提交',
        'ja': '提出',
        'ko': '제출',
        'vi': 'Gửi',
    },
    'name': {
        'en': 'Name',
        'zh-CN': '名称',
        'zh-TW': '名稱',
        'ja': '名前',
        'ko': '이름',
        'vi': 'Tên',
    },
    'description': {
        'en': 'Description',
        'zh-CN': '描述',
        'zh-TW': '描述',
        'ja': '説明',
        'ko': '설명',
        'vi': 'Mô tả',
    },
    'member': {
        'en': 'Member',
        'zh-CN': '成员',
        'zh-TW': '成員',
        'ja': 'メンバー',
        'ko': '멤버',
        'vi': 'Thành viên',
    },
    'create sth': {
        'en': 'Create {{0}}',
        'zh-CN': '创建{{0}}',
        'zh-TW': '創建{{0}}',
        'ja': '{{0}}を作成',
        'ko': '{{0}}을 생성',
        'vi': 'Tạo {{0}}',
    },
    'select sth': {
        'en': 'Select {{0}}',
        'zh-CN': '选择{{0}}',
        'zh-TW': '選擇{{0}}',
        'ja': '{{0}}を選択',
        'ko': '{{0}}을 선택',
        'vi': 'Chọn {{0}}',
    },
    'organization': {
        'en': 'Organization',
        'zh-CN': '组织',
        'zh-TW': '組織',
        'ja': '組織',
        'ko': '조직',
        'vi': 'Tổ chức',
    },
    'cluster': {
        'en': 'Cluster',
        'zh-CN': '集群',
        'zh-TW': '集群',
        'ja': 'クラスター',
        'ko': '클러스터',
        'vi': 'Hệ thống',
    },
    'deployment': {
        'en': 'Deployment',
        'zh-CN': '部署',
        'zh-TW': '部署',
        'ja': 'デプロイ',
        'ko': '배포',
        'vi': 'Cấp phát',
    },
    'deployment revision': {
        'en': 'Deployment Revision',
        'zh-CN': '部署版本',
        'zh-TW': '部署版本',
        'ja': 'デプロイリビジョン',
        'ko': '배포 리비전',
        'vi': 'Phiên bản cấp phát',
    },
    'revision': {
        'en': 'Revision',
        'zh-CN': '版本',
        'zh-TW': '版本',
        'ja': 'リビジョン',
        'ko': '리비전',
        'vi': 'Phiên bản',
    },
    'rollback': {
        'en': 'Rollback',
        'zh-CN': '回滚',
        'zh-TW': '回滾',
        'ja': 'ロールバック',
        'ko': '롤백',
        'vi': 'Lùi lại',
    },
    'bento': {
        'en': 'Bento',
        'zh-CN': 'Bento',
        'zh-TW': 'Bento',
        'ja': 'Bento',
        'ko': 'Bento',
        'vi': 'Bento',
    },
    'bento version': {
        'zh-CN': 'Bento 版本',
        'zh-TW': 'Bento 版本',
        'en': 'Bento Version',
        'ja': 'Bento バージョン',
        'ko': 'Bento 버전',
        'vi': 'Phiên bản Bento',
    },
    'register': {
        'en': 'Register',
        'zh-CN': '注册',
        'zh-TW': '註冊',
        'ja': '登録',
        'ko': '등록',
        'vi': 'Đăng ký',
    },
    'login': {
        'en': 'Login',
        'zh-CN': '登录',
        'zh-TW': '登錄',
        'ja': 'ログイン',
        'ko': '로그인',
        'vi': 'Đăng nhập',
    },
    'logout': {
        'en': 'Logout',
        'zh-CN': '登出',
        'zh-TW': '登出',
        'ja': 'ログアウト',
        'ko': '로그아웃',
        'vi': 'Đăng xuất',
    },
    'sth required': {
        'zh-CN': '需要填写{{0}}',
        'zh-TW': '需要填寫{{0}}',
        'en': '{{0}} was required',
        'ja': '{{0}}が必要です',
        'ko': '{{0}}이 필요합니다',
        'vi': '{{0}} đã được yêu cầu',
    },
    'no data': {
        'zh-CN': '暂无数据',
        'zh-TW': '暫無數據',
        'en': 'No Data',
        'ja': 'データがありません',
        'ko': '데이터가 없습니다',
        'vi': 'Không có dữ liệu',
    },
    'latest version': {
        'zh-CN': '最新版',
        'zh-TW': '最新版',
        'en': 'Latest Version',
        'ja': '最新バージョン',
        'ko': '최신 버전',
        'vi': 'Phiên bản mới nhất',
    },
    'version': {
        'zh-CN': '版本',
        'zh-TW': '版本',
        'en': 'Version',
        'ja': 'バージョン',
        'ko': '버전',
        'vi': 'Phiên bản',
    },
    'status': {
        'zh-CN': '状态',
        'zh-TW': '狀態',
        'en': 'Status',
        'ja': 'ステータス',
        'ko': '상태',
        'vi': 'Trạng thái',
    },
    'status name': {
        'zh-CN': '状态名称',
        'zh-TW': '狀態名稱',
        'en': 'Status Name',
        'ja': 'ステータス名',
        'ko': '상태 이름',
        'vi': 'Tên trạng thái',
    },
    'image build status': {
        'zh-CN': '镜像编译状态',
        'zh-TW': '鏡像編譯狀態',
        'en': 'Image Build Status',
        'ja': 'イメージビルドステータス',
        'ko': '이미지 빌드 상태',
        'vi': 'Tình trạng xây dựng ảnh',
    },
    'user profile': {
        'zh-CN': '用户资料',
        'zh-TW': '用戶資料',
        'en': 'User Profile',
        'ja': 'ユーザープロファイル',
        'ko': '사용자 프로필',
        'vi': 'Hồ sơ người dùng',
    },
    'api token': {
        'zh-CN': 'API 令牌',
        'zh-TW': 'API 令牌',
        'en': 'API Token',
        'ja': 'API トークン',
        'ko': 'API 토큰',
        'vi': 'API mã',
    },
    'production': {
        'zh-CN': '正式',
        'zh-TW': '正式',
        'en': 'Production',
        'ja': '製品',
        'ko': '상용',
        'vi': 'Sản phẩm',
    },
    'stable': {
        'zh-CN': '稳定',
        'zh-TW': '穩定',
        'en': 'Stable',
        'ja': '安定',
        'ko': '안정',
        'vi': 'Đảm bảo',
    },
    'canary': {
        'zh-CN': '金丝雀',
        'zh-TW': '金丝雀',
        'en': 'Canary',
        'ja': 'キャニー',
        'ko': '카나리아',
        'vi': 'Canary',
    },
    'canary rules': {
        'zh-CN': '金丝雀规则',
        'zh-TW': '金丝雀規則',
        'en': 'Canary Rules',
        'ja': 'キャニールール',
        'ko': '카나리아 규칙',
        'vi': 'Quy tắc Canary',
    },
    'weight': {
        'zh-CN': '权重',
        'zh-TW': '權重',
        'en': 'Weight',
        'ja': 'ウェイト',
        'ko': 'Weight',
        'vi': 'Trọng lượng',
    },
    'header': {
        'zh-CN': 'Header',
        'zh-TW': '標題',
        'en': 'Header',
        'ja': 'Header',
        'ko': 'Header',
        'vi': 'Header',
    },
    'cookie': {
        'zh-CN': 'Cookie',
        'zh-TW': 'Cookie',
        'en': 'Cookie',
        'ja': 'Cookie',
        'ko': 'Cookie',
        'vi': 'Cookie',
    },
    'header value': {
        'zh-CN': 'Header 值',
        'zh-TW': '標題值',
        'en': 'Header value',
        'ja': 'Header 値',
        'ko': 'Header 값',
        'vi': 'Giá trị Header',
    },
    'add app deployment canary rule': {
        'zh-CN': '增加灰度发布规则',
        'zh-TW': '增加灰度發布規則',
        'en': 'Add app deployment gray rule',
        'ja': 'アプリデプロイ灰度ルールを追加',
        'ko': '앱 배포 회색 규칙 추가',
        'vi': 'Thêm quy tắc bảo trì ứng dụng',
    },
    'create canary deploy': {
        'zh-CN': '创建灰度发布',
        'zh-TW': '創建灰度發布',
        'en': 'Create gray deploy',
        'ja': '灰度デプロイを作成',
        'ko': '회색 배포 생성',
        'vi': 'Tạo bảo trì ứng dụng',
    },
    'create deploy': {
        'zh-CN': '创建部署',
        'zh-TW': '創建部署',
        'en': 'Create deploy',
        'ja': 'デプロイを作成',
        'ko': '배포 생성',
        'vi': 'Tạo bản cập nhật',
    },
    'force': {
        'zh-CN': '强制',
        'zh-TW': '強制',
        'en': 'Force',
        'ja': '強制',
        'ko': '강제',
        'vi': 'Force',
    },
    'deployment detail': {
        'zh-CN': '部署详情',
        'zh-TW': '部署詳情',
        'en': 'Deployment Detail',
        'ja': 'デプロイ詳細',
        'ko': '배포 세부 사항',
        'vi': 'Chi tiết bản cập nhật',
    },
    'visibility': {
        'zh-CN': '可见性',
        'zh-TW': '可見性',
        'en': 'Visibility',
        'ja': '可視性',
        'ko': '가시성',
        'vi': 'Hiển thị',
    },
    'non-deployed': {
        'zh-CN': '未部署',
        'zh-TW': '未部署',
        'en': 'Non Deployed',
        'ja': '未デプロイ',
        'ko': '배포되지 않음',
        'vi': 'Chưa cập nhật',
    },
    'unhealthy': {
        'zh-CN': '不健康',
        'zh-TW': '不健康',
        'en': 'Unhealthy',
        'ja': '不健康',
        'ko': '안정적이지 않음',
        'vi': 'Không ổn định',
    },
    'failed': {
        'zh-CN': '失败',
        'zh-TW': '失敗',
        'en': 'Failed',
        'ja': '失敗',
        'ko': '실패',
        'vi': 'Thất bại',
    },
    'deploying': {
        'zh-CN': '部署中',
        'zh-TW': '部署中',
        'en': 'Deploying',
        'ja': 'デプロイ中',
        'ko': '배포 중',
        'vi': 'Đang cập nhật',
    },
    'running': {
        'zh-CN': '运行中',
        'zh-TW': '運行中',
        'en': 'Running',
        'ja': '実行中',
        'ko': '실행 중',
        'vi': 'Đang chạy',
    },
    'unknown': {
        'zh-CN': '未知',
        'zh-TW': '未知',
        'en': 'Unknown',
        'ja': '不明',
        'ko': '알 수 없음',
        'vi': 'Không rõ',
    },
    'image-building': {
        'zh-CN': '镜像构建中',
        'zh-TW': '鏡像構建中',
        'en': 'Image Building',
        'ja': 'イメージ構築中',
        'ko': '이미지 빌드 중',
        'vi': 'Đang xây dựng hình ảnh',
    },
    'image-build-failed': {
        'zh-CN': '镜像构建失败',
        'zh-TW': '鏡像構建失敗',
        'en': 'Image Build Failed',
        'ja': 'イメージ構築失敗',
        'ko': '이미지 빌드 실패',
        'vi': 'Xây dựng hình ảnh thất bại',
    },
    'image-build-succeeded': {
        'zh-CN': '镜像构建成功',
        'zh-TW': '鏡像構建成功',
        'en': 'Image Build Succeeded',
        'ja': 'イメージ構築成功',
        'ko': '이미지 빌드 성공',
        'vi': 'Xây dựng hình ảnh thành công',
    },
    'replicas': {
        'zh-CN': '副本',
        'zh-TW': '副本',
        'en': 'Replicas',
        'ja': '副本',
        'ko': '레플리카',
        'vi': 'Phiên bản',
    },
    'type': {
        'zh-CN': '类型',
        'zh-TW': '類型',
        'en': 'Type',
        'ja': 'タイプ',
        'ko': '유형',
        'vi': 'Loại',
    },
    'view log': {
        'zh-CN': '查看日志',
        'zh-TW': '查看日誌',
        'en': 'View Log',
        'ja': 'ログを見る',
        'ko': '로그 보기',
        'vi': 'Xem nhật ký',
    },
    'view terminal history': {
        'zh-CN': '查看终端操作记录',
        'zh-TW': '查看終端操作記錄',
        'en': 'View Terminal History',
        'ja': '終端操作履歴を見る',
        'ko': '터미널 기록 보기',
        'vi': 'Xem lịch sử của máy tính đầu cuối',
    },
    'download log': {
        'zh-CN': '下载日志',
        'zh-TW': '下載日誌',
        'en': 'Download Log',
        'ja': 'ログをダウンロード',
        'ko': '로그 다운로드',
        'vi': 'Tải nhật ký',
    },
    'log forward': {
        'zh-CN': '日志正序',
        'zh-TW': '日誌正序',
        'en': 'Log Forward',
        'ja': 'ログ正序',
        'ko': '로그 정렬',
        'vi': 'Nhật ký tăng dần',
    },
    'stop scroll': {
        'zh-CN': '停止滚动',
        'zh-TW': '停止滾動',
        'en': 'Stop Scroll',
        'ja': 'スクロールを停止',
        'ko': '스크롤 중지',
        'vi': 'Dừng cuộn',
    },
    'scroll': {
        'zh-CN': '滚动',
        'zh-TW': '滾動',
        'en': 'Scroll',
        'ja': 'スクロール',
        'ko': '스크롤',
        'vi': 'Cuộn',
    },
    'all pods': {
        'zh-CN': '所有的 Pod',
        'zh-TW': '所有的 Pod',
        'en': 'All Pods',
        'ja': 'すべての Pod',
        'ko': '모든 Pod',
        'vi': 'Tất cả Pod',
    },
    'lines': {
        'zh-CN': '行数',
        'zh-TW': '行數',
        'en': 'Lines',
        'ja': '行数',
        'ko': '줄',
        'vi': 'Dòng',
    },
    'realtime': {
        'zh-CN': '实时',
        'zh-TW': '即時',
        'en': 'Realtime',
        'ja': 'リアルタイム',
        'ko': '실시간',
        'vi': 'Thời gian thực',
    },
    'max lines': {
        'zh-CN': '最大行数',
        'zh-TW': '最大行數',
        'en': 'Max Lines',
        'ja': '最大行数',
        'ko': '최대 줄',
        'vi': 'Dòng tối đa',
    },
    'advanced': {
        'zh-CN': '高级',
        'zh-TW': '高級',
        'en': 'Advanced',
        'ja': '高度',
        'ko': '고급',
        'vi': 'Nâng cao',
    },
    'cpu limits': {
        'zh-CN': 'CPU 资源限制',
        'zh-TW': 'CPU 資源限制',
        'en': 'CPU Resources Limits',
        'ja': 'CPU リソースリミット',
        'ko': 'CPU 자원 제한',
        'vi': 'Giới hạn CPU',
    },
    'gpu limits': {
        'zh-CN': 'GPU 资源限制',
        'zh-TW': 'GPU 資源限制',
        'en': 'GPU Resources Limits',
        'ja': 'GPU リソースリミット',
        'ko': 'GPU 자원 제한',
        'vi': 'Giới hạn GPU',
    },
    'gpu requests': {
        'zh-CN': 'GPU 资源请求',
        'zh-TW': 'GPU 資源請求',
        'en': 'GPU Resources Requests',
        'ja': 'GPU リソースリクエスト',
        'ko': 'GPU 자원 요청',
        'vi': 'Yêu cầu GPU',
    },
    'cpu': {
        'zh-CN': 'CPU',
        'zh-TW': 'CPU',
        'en': 'CPU',
        'ja': 'CPU',
        'ko': 'CPU',
        'vi': 'CPU',
    },
    'gpu': {
        'zh-CN': '显卡',
        'zh-TW': '显卡',
        'en': 'GPU',
        'ja': 'GPU',
        'ko': 'GPU',
        'vi': 'GPU',
    },
    'memory': {
        'zh-CN': '内存',
        'zh-TW': '記憶體',
        'en': 'Memory',
        'ja': 'メモリ',
        'ko': '메모리',
        'vi': 'Bộ nhớ',
    },
    'memory limits': {
        'zh-CN': '内存资源限制',
        'zh-TW': '記憶體資源限制',
        'en': 'Memory Resources Limits',
        'ja': 'メモリリソースリミット',
        'ko': '메모리 자원 제한',
        'vi': 'Giới hạn bộ nhớ',
    },
    'cpu requests': {
        'zh-CN': 'CPU 资源请求',
        'zh-TW': 'CPU 資源請求',
        'en': 'CPU Resources Requests',
        'ja': 'CPU リソースリクエスト',
        'ko': 'CPU 자원 요청',
        'vi': 'Yêu cầu CPU',
    },
    'memory requests': {
        'zh-CN': '内存资源请求',
        'zh-TW': '記憶體資源請求',
        'en': 'Memory Resources Requests',
        'ja': 'メモリ リソースリクエスト',
        'ko': '메모리 자원 요청',
        'vi': 'Yêu cầu bộ nhớ',
    },
    'add': {
        'zh-CN': '添加',
        'zh-TW': '添加',
        'en': 'Add',
        'ja': '追加',
        'ko': '추가',
        'vi': 'Thêm',
    },
    'action': {
        'zh-CN': '行为',
        'zh-TW': '行為',
        'en': 'Action',
        'ja': 'アクション',
        'ko': '행동',
        'vi': 'Hành động',
    },
    'Terminating': {
        'zh-CN': '结束中',
        'zh-TW': '結束中',
        'en': 'Terminating',
        'ja': '終了中',
        'ko': '종료 중',
        'vi': 'Đang kết thúc',
    },
    'ContainerCreating': {
        'zh-CN': '创建中',
        'zh-TW': '創建中',
        'en': 'Creating',
        'ja': '作成中',
        'ko': '생성 중',
        'vi': 'Đang tạo',
    },
    'pending': {
        'zh-CN': '等待中',
        'zh-TW': '等待中',
        'en': 'Pending',
        'ja': '保留中',
        'ko': '대기 중',
        'vi': 'Đang chờ',
    },
    'building': {
        'zh-CN': '编译中',
        'zh-TW': '編譯中',
        'en': 'Building',
        'ja': 'ビルド中',
        'ko': '빌드 중',
        'vi': 'Đang xây dựng',
    },
    'Pending': {
        'zh-CN': '等待中',
        'zh-TW': '等待中',
        'en': 'Pending',
        'ja': '保留中',
        'ko': '대기 중',
        'vi': 'Đang chờ',
    },
    'Running': {
        'zh-CN': '运行中',
        'zh-TW': '運行中',
        'en': 'Running',
        'ja': '実行中',
        'ko': '실행 중',
        'vi': 'Đang chạy',
    },
    'success': {
        'zh-CN': '成功',
        'zh-TW': '成功',
        'en': 'Succeeded',
        'ja': '成功',
        'ko': '성공',
        'vi': 'Thành công',
    },
    'Succeeded': {
        'zh-CN': '成功',
        'zh-TW': '成功',
        'en': 'Succeeded',
        'ja': '成功',
        'ko': '성공',
        'vi': 'Thành công',
    },
    'Failed': {
        'zh-CN': '失败',
        'zh-TW': '失敗',
        'en': 'Failed',
        'ja': '失敗',
        'ko': '실패',
        'vi': 'Thất bại',
    },
    'Unknown': {
        'zh-CN': '未知',
        'zh-TW': '未知',
        'en': 'Unknown',
        'ja': '不明',
        'ko': '알 수 없음',
        'vi': 'Không rõ',
    },
    'start time': {
        'zh-CN': '启动时间',
        'zh-TW': '啟動時間',
        'en': 'Start Time',
        'ja': '開始時間',
        'ko': '시작 시간',
        'vi': 'Thời gian bắt đầu',
    },
    'end time': {
        'zh-CN': '结束时间',
        'zh-TW': '結束時間',
        'en': 'End Time',
        'ja': '終了時間',
        'ko': '종료 시간',
        'vi': 'Thời gian kết thúc',
    },
    'terminal': {
        'zh-CN': '终端',
        'zh-TW': '終端',
        'en': 'Terminal',
        'ja': '終端',
        'ko': '종료',
        'vi': 'Kết thúc',
    },
    'operation': {
        'en': 'Operation',
        'zh-CN': '操作',
        'zh-TW': '操作',
        'ja': '操作',
        'ko': '작업',
        'vi': 'Hoạt động',
    },
    'pod': {
        'zh-CN': 'Pod',
        'zh-TW': 'Pod',
        'en': 'Pod',
        'ja': 'Pod',
        'ko': 'Pod',
        'vi': 'Pod',
    },
    'container': {
        'zh-CN': '容器',
        'zh-TW': '容器',
        'en': 'Container',
        'ja': 'コンテナ',
        'ko': '컨테이너',
        'vi': 'Thực thể',
    },
    'playback operation': {
        'zh-CN': '回放操作',
        'zh-TW': '回放操作',
        'en': 'Playback Operation',
        'ja': 'プレイバック操作',
        'ko': '돌아가기 작업',
        'vi': 'Lặp lại hoạt động',
    },
    'no event': {
        'zh-CN': '暂无事件',
        'zh-TW': '暫無事件',
        'en': 'No Event',
        'ja': 'イベントなし',
        'ko': '이벤트 없음',
        'vi': 'Không có sự kiện',
    },
    'event': {
        'zh-CN': '事件',
        'zh-TW': '事件',
        'en': 'Event',
        'ja': 'イベント',
        'ko': '이벤트',
        'vi': 'Sự kiện',
    },
    'events': {
        'zh-CN': '事件列表',
        'zh-TW': '事件列表',
        'en': 'Events',
        'ja': 'イベントリスト',
        'ko': '이벤트 목록',
        'vi': 'Danh sách sự kiện',
    },
    'node': {
        'zh-CN': '节点',
        'zh-TW': '节点',
        'en': 'Node',
        'ja': 'ノード',
        'ko': '노드',
        'vi': 'Nút',
    },
    'settings': {
        'zh-CN': '设置',
        'zh-TW': '設定',
        'en': 'Settings',
        'ja': '設定',
        'ko': '설정',
        'vi': 'Cài đặt',
    },
    'regexp': {
        'zh-CN': '正则表达式',
        'zh-TW': '正則表達式',
        'en': 'Regexp',
        'ja': '正規表現',
        'ko': '정규 표현식',
        'vi': 'Biểu thức chính quy',
    },
    'contains': {
        'zh-CN': '包含',
        'zh-TW': '包含',
        'en': 'Contains',
        'ja': '含む',
        'ko': '포함',
        'vi': 'Bao gồm',
    },
    'not contains': {
        'zh-CN': '不包含',
        'zh-TW': '不包含',
        'en': 'Not Contains',
        'ja': '含まない',
        'ko': '포함 않음',
        'vi': 'Không bao gồm',
    },
    'clear': {
        'zh-CN': '清空',
        'zh-TW': '清空',
        'en': 'Clear',
        'ja': 'クリア',
        'ko': '지우기',
        'vi': 'Xóa',
    },
    'and': {
        'zh-CN': '且',
        'zh-TW': '且',
        'en': 'And',
        'ja': '且',
        'ko': '그리고',
        'vi': 'Và',
    },
    'add filter condition': {
        'zh-CN': '添加过滤条件',
        'zh-TW': '添加過濾條件',
        'en': 'Add Filter Condition',
        'ja': 'フィルタ条件を追加',
        'ko': '필터 조건 추가',
        'vi': 'Thêm điều kiện lọc',
    },
    'match': {
        'zh-CN': '匹配',
        'zh-TW': '匹配',
        'en': 'Match',
        'ja': 'マッチ',
        'ko': '일치',
        'vi': 'Khớp',
    },
    'not match': {
        'zh-CN': '不匹配',
        'zh-TW': '不匹配',
        'en': 'Not Match',
        'ja': 'マッチしない',
        'ko': '일치하지 않음',
        'vi': 'Không khớp',
    },
    'advanced search': {
        'zh-CN': '高级搜索',
        'zh-TW': '高級搜索',
        'en': 'Advanced Search',
        'ja': '高度な検索',
        'ko': '고급 검색',
        'vi': 'Tìm kiếm nâng cao',
    },
    'search': {
        'zh-CN': '搜索',
        'zh-TW': '搜索',
        'en': 'Search',
        'ja': '検索',
        'ko': '검색',
        'vi': 'Tìm kiếm',
    },
    'please enter keywords': {
        'zh-CN': '请输入关键词',
        'zh-TW': '請輸入關鍵詞',
        'en': 'Please enter keywords',
        'ja': 'キーワードを入力してください',
        'ko': '키워드를 입력하십시오',
        'vi': 'Vui lòng nhập từ khóa',
    },
    'yatai component': {
        'zh-CN': 'Yatai 组件',
        'zh-TW': 'Yatai 組件',
        'en': 'Yatai Component',
        'ja': 'Yatai コンポーネント',
        'ko': 'Yatai 구성 요소',
        'vi': 'Yatai thành phần',
    },
    'deployed': {
        'zh-CN': '部署完毕',
        'zh-TW': '部署完畢',
        'en': 'Deployed',
        'ja': 'デプロイ済み',
        'ko': '배포 완료',
        'vi': 'Đã được cấp phát',
    },
    'uninstalled': {
        'zh-CN': '卸载完毕',
        'zh-TW': '卸載完畢',
        'en': 'Uninstalled',
        'ja': 'アンインストール済み',
        'ko': '제거 완료',
        'vi': 'Đã gỡ cài đặt',
    },
    'superseded': {
        'zh-CN': '已废弃',
        'zh-TW': '已廢棄',
        'en': 'Superseded',
        'ja': '廃止済み',
        'ko': '사법 제한',
        'vi': 'Đã bị thay thế',
    },
    'uninstalling': {
        'zh-CN': '卸载中',
        'zh-TW': '卸載中',
        'en': 'Uninstalling',
        'ja': 'アンインストール中',
        'ko': '제거 중',
        'vi': 'Đang gỡ cài đặt',
    },
    'pending-install': {
        'zh-CN': '等待安装',
        'zh-TW': '等待安裝',
        'en': 'Pending Install',
        'ja': 'インストール保留中',
        'ko': '설치 대기 중',
        'vi': 'Đang chờ cài đặt',
    },
    'pending-upgrade': {
        'zh-CN': '等待升级',
        'zh-TW': '等待升級',
        'en': 'Pending Upgrade',
        'ja': 'アップグレード保留中',
        'ko': '업그레이드 대기 중',
        'vi': 'Đang chờ nâng cấp',
    },
    'pending-rollback': {
        'zh-CN': '等待回滚',
        'zh-TW': '等待回退',
        'en': 'Pending Rollback',
        'ja': 'リバック保留中',
        'ko': '롤백 대기 중',
        'vi': 'Đang chờ lùi lại',
    },
    'logging': {
        'zh-CN': '日志',
        'zh-TW': '日誌',
        'en': 'Logging',
        'ja': 'ログ',
        'ko': '로그',
        'vi': 'Nhật ký',
    },
    'monitoring': {
        'zh-CN': '监控',
        'zh-TW': '監控',
        'en': 'Monitoring',
        'ja': 'モニタリング',
        'ko': '감시',
        'vi': 'Theo dõi',
    },
    'please install yatai component first': {
        'zh-CN': '请先安装 Yatai {{0}} 组件',
        'zh-TW': '請先安裝 Yatai {{0}} 組件',
        'en': 'Please install yatai component {{0}} first',
        'ja': 'Yatai コンポーネント {{0}} を先にインストールしてください',
        'ko': 'Yatai 구성 요소 {{0}} 먼저 설치하십시오',
        'vi': 'Vui lòng cài đặt yatai thành phần {{0}} trước',
    },
    'monitor': {
        'zh-CN': '监控器',
        'zh-TW': '監控器',
        'en': 'Monitor',
        'ja': 'モニター',
        'ko': '감시자',
        'vi': 'Bộ đo',
    },
    'upgrade': {
        'zh-CN': '升级',
        'zh-TW': '升級',
        'en': 'Upgrade',
        'ja': 'アップグレード',
        'ko': '업그레이드',
        'vi': 'Nâng cấp',
    },
    'upgrade to sth': {
        'zh-CN': '升级到 {{0}}',
        'zh-TW': '升級到 {{0}}',
        'en': 'Upgrade to {{0}}',
        'ja': '{{0}} にアップグレード',
        'ko': '{{0}} 업그레이드',
        'vi': 'Nâng cấp đến {{0}}',
    },
    'do you want to upgrade yatai component sth to some version': {
        'zh-CN': '你想把 Yatai 组件 {{0}} 升级到 {{1}} 版本吗？',
        'zh-TW': '你想把 Yatai 組件 {{0}} 升級到 {{1}} 版本吗？',
        'en': 'Do you want to upgrade yatai component {{0}} to {{1}} version?',
        'ja': 'Yatai コンポーネント {{0}} を {{1}} バージョンにアップグレードしますか？',
        'ko': 'Yatai 구성 요소 {{0}} 버전을 {{1}} 버전으로 업그레이드하시겠습니까?',
        'vi': 'Bạn có muốn nâng cấp yatai thành phần {{0}} đến phiên bản {{1}} không?',
    },
    'do you want to delete yatai component sth': {
        'zh-CN': '你想删除 Yatai 组件 {{0}} 吗？',
        'zh-TW': '你想刪除 Yatai 組件 {{0}} 吗？',
        'en': 'Do you want to delete yatai component {{0}}?',
        'ja': 'Yatai コンポーネント {{0}} を削除しますか？',
        'ko': 'Yatai 구성 요소 {{0}} 삭제하시겠습니까?',
        'vi': 'Bạn có muốn xóa yatai thành phần {{0}} không?',
    },
    'cancel': {
        'zh-CN': '取消',
        'zh-TW': '取消',
        'en': 'Cancel',
        'ja': 'キャンセル',
        'ko': '취소',
        'vi': 'Hủy',
    },
    'ok': {
        'zh-CN': '确定',
        'zh-TW': '確定',
        'en': 'Okay',
        'ja': 'OK',
        'ko': '확인',
        'vi': 'Đồng ý',
    },
    'delete': {
        'zh-CN': '删除',
        'zh-TW': '刪除',
        'en': 'Delete',
        'ja': '削除',
        'ko': '삭제',
        'vi': 'Xóa',
    },
    'kube resources': {
        'zh-CN': 'K8S 资源列表',
        'zh-TW': 'K8S 資源列表',
        'en': 'K8S Resources',
        'ja': 'K8S リソース一覧',
        'ko': 'K8S 자원 목록',
        'vi': 'K8S tài nguyên',
    },
    'helm release name': {
        'zh-CN': 'HELM 发布名称',
        'zh-TW': 'HELM 發布名稱',
        'en': 'HELM Release Name',
        'ja': 'HELM リリース名',
        'ko': 'HELM 발표 이름',
        'vi': 'HELM phát hành tên',
    },
    'helm chart name': {
        'zh-CN': 'HELM Chart 名称',
        'zh-TW': 'HELM Chart 名稱',
        'en': 'HELM Chart Name',
        'ja': 'HELM Chart 名',
        'ko': 'HELM Chart 이름',
        'vi': 'HELM Chart tên',
    },
    'helm chart description': {
        'zh-CN': 'HELM Chart 详情',
        'zh-TW': 'HELM Chart 詳情',
        'en': 'HELM Chart Description',
        'ja': 'HELM Chart 詳細',
        'ko': 'HELM Chart 세부 정보',
        'vi': 'HELM Chart chi tiết',
    },
    'model': {
        'zh-CN': '模型',
        'zh-TW': '模型',
        'en': 'Model',
        'ja': 'モデル',
        'ko': '모델',
        'vi': 'Mô hình',
    },
    'model versions': {
        'zh-CN': '模型版本',
        'zh-TW': '模型版本',
        'en': 'Model Versions',
        'ja': 'モデルバージョン',
        'ko': '모델 버전',
        'vi': 'Phiên bản mô hình',
    },
    'name or email': {
        'zh-CN': '用户名或者邮箱地址',
        'zh-TW': '用戶名或者郵箱地址',
        'en': 'Name or Email',
        'ja': 'ユーザー名またはメールアドレス',
        'ko': '이름 또는 이메일 주소',
        'vi': 'Tên người dùng hoặc địa chỉ email',
    },
    'password': {
        'zh-CN': '密码',
        'zh-TW': '密碼',
        'en': 'Password',
        'ja': 'パスワード',
        'ko': '암호',
        'vi': 'Mật khẩu',
    },
    'update': {
        'zh-CN': '更新',
        'zh-TW': '更新',
        'en': 'Update',
        'ja': '更新',
        'ko': '업데이트',
        'vi': 'Cập nhật',
    },
    'update sth': {
        'zh-CN': '更新 {{0}}',
        'zh-TW': '更新 {{0}}',
        'en': 'Update {{0}}',
        'ja': '{{0}} を更新',
        'ko': '{{0}} 업데이트',
        'vi': 'Cập nhật {{0}}',
    },
    'deployment target': {
        'zh-CN': '部署对象',
        'zh-TW': '部署對象',
        'en': 'Deployment Target',
        'ja': 'デプロイ対象',
        'ko': '배포 대상',
        'vi': 'Điểm đặt',
    },
    'add sth': {
        'zh-CN': '添加{{0}}',
        'zh-TW': '添加{{0}}',
        'en': 'Add {{0}}',
        'ja': '{{0}}を追加',
        'ko': '{{0}} 추가',
        'vi': 'Thêm {{0}}',
    },
    'api': {
        'zh-CN': 'API',
        'zh-TW': 'API',
        'en': 'API',
        'ja': 'API',
        'ko': 'API',
        'vi': 'API',
    },
    'read_organization': {
        'zh-CN': '读取组织',
        'zh-TW': '讀取組織',
        'en': 'Read Organization',
        'ja': '組織を読み込む',
        'ko': '조직 읽기',
        'vi': 'Đọc tổ chức',
    },
    'write_organization': {
        'zh-CN': '写入组织',
        'zh-TW': '寫入組織',
        'en': 'Write Organization',
        'ja': '組織を書き込む',
        'ko': '조직 쓰기',
        'vi': 'Viết tổ chức',
    },
    'read_cluster': {
        'zh-CN': '读取集群',
        'zh-TW': '讀取集群',
        'en': 'Read Cluster',
        'ja': 'クラスタを読み込む',
        'ko': '클러스터 읽기',
        'vi': 'Đọc hội',
    },
    'write_cluster': {
        'zh-CN': '写入集群',
        'zh-TW': '寫入集群',
        'en': 'Write Cluster',
        'ja': 'クラスタを書き込む',
        'ko': '클러스터 쓰기',
        'vi': 'Viết hội',
    },
    'scope api description': {
        'en': 'Grants complete read/write access to the API, including all organizations and clusters and deployments.',
        'zh-CN': '授予对 API 的完全读/写权限，包括所有组织和集群。',
        'zh-TW': '授予對 API 的完全讀/寫權限，包括所有組織和集群。',
        'ja': 'API の完全読/書き権限を与えます。',
        'ko': 'API 에 전체 읽기/쓰기 권한을 부여합니다. 전체 클러스터 및 조직을 포함합니다.',
        'vi': 'Cấp quyền đọc/ghi đầy đủ cho API, bao gồm tất cả tổ chức và hội.',
    },
    'scope read organization description': {
        'en': 'Grants read access to the organization, including all clusters and deployments.',
        'zh-CN': '授予对组织的读权限，包括所有集群和部署。',
        'zh-TW': '授予對組織的讀權限，包括所有集群和部署。',
        'ja': '組織の読み権限を与えます。',
        'ko': '조직 읽기 권한을 부여합니다. 클러스터 및 배포를 포함합니다.',
        'vi': 'Cấp quyền đọc tổ chức, bao gồm tất cả hội và các phiên bản.',
    },
    'scope write organization description': {
        'en': 'Grants read-write access to the organization, including all clusters and deployments.',
        'zh-CN': '授予对组织的读/写权限，包括所有集群和部署。',
        'zh-TW': '授予對組織的讀/寫權限，包括所有集群和部署。',
        'ja': '組織の読/書き権限を与えます。',
        'ko': '조직 읽기/쓰기 권한을 부여합니다. 클러스터 및 배포를 포함합니다.',
        'vi': 'Cấp quyền đọc/ghi tổ chức, bao gồm tất cả hội và các phiên bản.',
    },
    'scope read cluster description': {
        'en': 'Grants read access to the cluster, including all deployments.',
        'zh-CN': '授予对集群的读权限，包括所有部署。',
        'zh-TW': '授予對集群的讀權限，包括所有部署。',
        'ja': 'クラスタの読み権限を与えます。',
        'ko': '클러스터 읽기 권한을 부여합니다. 배포를 포함합니다.',
        'vi': 'Cấp quyền đọc hội, bao gồm tất cả phiên bản.',
    },
    'scope write cluster description': {
        'en': 'Grants read-write access to the cluster, including all deployments.',
        'zh-CN': '授予对集群的读/写权限，包括所有部署。',
        'zh-TW': '授予對集群的讀/寫權限，包括所有部署。',
        'ja': 'クラスタの読/書き権限を与えます。',
        'ko': '클러스터 읽기/쓰기 권한을 부여합니다. 배포를 포함합니다.',
        'vi': 'Cấp quyền đọc/ghi hội, bao gồm tất cả phiên bản.',
    },
    'scopes': {
        'zh-CN': '权限',
        'zh-TW': '權限',
        'en': 'Scopes',
        'ja': '権限',
        'ko': '권한',
        'vi': 'Phạm vi',
    },
    'expired_at': {
        'zh-CN': '过期时间',
        'zh-TW': '過期時間',
        'en': 'Expired At',
        'ja': '有効期限',
        'ko': '만료 시간',
        'vi': 'Hết hạn',
    },
    'last_used_at': {
        'zh-CN': '最后使用时间',
        'zh-TW': '最後使用時間',
        'en': 'Last Used At',
        'ja': '最終使用日時',
        'ko': '마지막 사용 시간',
        'vi': 'Thời gian sử dụng cuối cùng',
    },
    'api token only show once time tips': {
        'zh-CN': 'API Token 只会显示一次，请保存好且不要泄露此 Token',
        'zh-TW': 'API Token 只會顯示一次，請保存好且不要泄露此 Token',
        'en': 'API Token will only show once, please save it and do not disclose it.',
        'ja': 'API Token は一度だけ表示されます。保存してください。',
        'ko': 'API 토큰은 한 번만 표시됩니다. 저장하고 안전하게 비밀번호를 알려주세요.',
        'vi': 'API Token sẽ chỉ hiển thị một lần, hãy lưu và không bao giờ nói chuyện với nó.',
    },
    'copy': {
        'zh-CN': '复制',
        'zh-TW': '複製',
        'en': 'Copy',
        'ja': 'コピー',
        'ko': '복사',
        'vi': 'Sao chép',
    },
    'copied to clipboard': {
        'zh-CN': '已复制到剪贴板',
        'zh-TW': '已複製到剪貼板',
        'en': 'Copied to clipboard',
        'ja': 'クリップボードにコピーしました',
        'ko': '클립보드에 복사되었습니다.',
        'vi': 'Đã sao chép vào clipboard',
    },
    'newest': {
        'zh-CN': '最新',
        'zh-TW': '最新',
        'en': 'Newest',
        'ja': '最新',
        'ko': '최신',
        'vi': 'Mới nhất',
    },
    'oldest': {
        'zh-CN': '最旧',
        'zh-TW': '最舊',
        'en': 'Oldest',
        'ja': '最古',
        'ko': '오래된',
        'vi': 'Cũ nhất',
    },
    'sort': {
        'zh-CN': '排序',
        'zh-TW': '排序',
        'en': 'Sort',
        'ja': 'ソート',
        'ko': '정렬',
        'vi': 'Sắp xếp',
    },
    'last updater': {
        'zh-CN': '最后更新者',
        'zh-TW': '最後更新者',
        'en': 'Last Updater',
        'ja': '最終更新者',
        'ko': '마지막 업데이트',
        'vi': 'Người cập nhật cuối cùng',
    },
    'updated_at': {
        'zh-CN': '更新时间',
        'zh-TW': '更新時間',
        'en': 'Updated At',
        'ja': '更新日時',
        'ko': '업데이트 시간',
        'vi': 'Thời gian cập nhật',
    },
    'newest update': {
        'zh-CN': '最新更新',
        'zh-TW': '最新更新',
        'en': 'Newest Update',
        'ja': '最新更新',
        'ko': '최신 업데이트',
        'vi': 'Cập nhật mới nhất',
    },
    'oldest update': {
        'zh-CN': '最旧更新',
        'zh-TW': '最舊更新',
        'en': 'Oldest Update',
        'ja': '最古更新',
        'ko': '오래된 업데이트',
        'vi': 'Cập nhật cũ nhất',
    },
    'newest build': {
        'zh-CN': '最新构建',
        'zh-TW': '最新構建',
        'en': 'Newest Build',
        'ja': '最新ビルド',
        'ko': '최신 빌드',
        'vi': 'Bản xây dựng mới nhất',
    },
    'oldest build': {
        'zh-CN': '最旧构建',
        'zh-TW': '最舊構建',
        'en': 'Oldest Build',
        'ja': '最古ビルド',
        'ko': '오래된 빌드',
        'vi': 'Bản xây dựng cũ nhất',
    },
    'filter by sth': {
        'zh-CN': '按照 {{0}} 过滤',
        'zh-TW': '按照 {{0}} 过滤',
        'en': 'Filter by {{0}}',
        'ja': '{{0}} をフィルター',
        'ko': '{{0}} 필터링',
        'vi': 'Lọc theo {{0}}',
    },
    'clear search keyword, filters and sorts': {
        'zh-CN': '清除搜索关键词、过滤器和排序规则',
        'zh-TW': '清除搜索關鍵詞、過濾器和排序規則',
        'en': 'Clear search keyword, filters and sorts',
        'ja': '検索キーワード、フィルターとソートをクリア',
        'ko': '검색 키워드, 필터링 및 정렬 규칙을 지웁니다.',
        'vi': 'Xóa từ khóa tìm kiếm, bộ lọc và quy tắc sắp xếp',
    },
    'filters': {
        'zh-CN': '过滤器',
        'zh-TW': '過濾器',
        'en': 'Filters',
        'ja': 'フィルター',
        'ko': '필터링',
        'vi': 'Bộ lọc',
    },
    'the deployments I created': {
        'zh-CN': '我创建的部署',
        'zh-TW': '我創建的部署',
        'en': 'The deployments I created',
        'ja': '私が作成した部署',
        'ko': '내가 만든 배포',
        'vi': 'Các phiên bản đã tạo bởi tôi',
    },
    'my last updated deployments': {
        'zh-CN': '我最后更新的部署',
        'zh-TW': '我最後更新的部署',
        'en': 'My last updated deployments',
        'ja': 'マイ・最終更新デプロイ',
        'ko': '내 마지막 업데이트 배포',
        'vi': 'Các phiên bản đã cập nhật cuối cùng của tôi',
    },
    'bento repository': {
        'zh-CN': 'Bento 仓库',
        'zh-TW': 'Bento 倉庫',
        'en': 'Bento Repository',
        'ja': 'Bento リポジトリ',
        'ko': 'Bento 저장소',
        'vi': 'Kho hàng Bento',
    },
    'model repository': {
        'zh-CN': '模型仓库',
        'zh-TW': '模型倉庫',
        'en': 'Model Repository',
        'ja': 'モデルリポジトリ',
        'ko': '모델 저장소',
        'vi': 'Kho hàng mô hình',
    },
    'the models I created': {
        'zh-CN': '我创建的 Model',
        'zh-TW': '我創建的 Model',
        'en': 'The models I created',
        'ja': '私が作成した Model',
        'ko': '내가 만든 모델',
        'vi': 'Các mô hình đã tạo bởi tôi',
    },
    'the bentos I created': {
        'zh-CN': '我创建的 Bento',
        'zh-TW': '我創建的 Bento',
        'en': 'The bentos I created',
        'ja': '私が作成した Bento',
        'ko': '내가 만든 Bento',
        'vi': 'Các bento đã tạo bởi tôi',
    },
    'the bento versions I Created': {
        'zh-CN': '我创建的 Bento 版本',
        'zh-TW': '我創建的 Bento 版本',
        'en': 'The bento versions I Created',
        'ja': '私が作成した Bento バージョン',
        'ko': '내가 만든 Bento 버전',
        'vi': 'Các phiên bản bento đã tạo bởi tôi',
    },
    'my last updated bentos': {
        'zh-CN': '我最后更新的 Bento',
        'zh-TW': '我最後更新的 Bento',
        'en': 'My last updated bentos',
        'ja': 'マイ・最終更新 Bento',
        'ko': '내 마지막 업데이트 Bento',
        'vi': 'Các bento đã cập nhật cuối cùng của tôi',
    },
    'terminate': {
        'zh-CN': '终止',
        'zh-TW': '終止',
        'en': 'Terminate',
        'ja': '終了',
        'ko': '종료',
        'vi': 'Kết thúc',
    },
    'terminate sth': {
        'zh-CN': '终止{{0}}',
        'zh-TW': '終止{{0}}',
        'en': 'Terminate {{0}}',
        'ja': '{{0}}を終了',
        'ko': '{{0}} 종료',
        'vi': 'Kết thúc {{0}}',
    },
    'delete sth': {
        'zh-CN': '删除{{0}}',
        'zh-TW': '刪除{{0}}',
        'en': 'Delete {{0}}',
        'ja': '{{0}}を削除',
        'ko': '{{0}} 삭제',
        'vi': 'Xóa {{0}}',
    },
    'restore': {
        'zh-CN': '恢复',
        'zh-TW': '恢復',
        'en': 'Restore',
        'ja': '復元',
        'ko': '복원',
        'vi': 'Khôi phục',
    },
    'terminating': {
        'zh-CN': '终止中',
        'zh-TW': '終止中',
        'en': 'Terminating',
        'ja': '終了中',
        'ko': '종료 중',
        'vi': 'Đang kết thúc',
    },
    'terminated': {
        'zh-CN': '已终止',
        'zh-TW': '已終止',
        'en': 'Terminated',
        'ja': '終了しました',
        'ko': '종료됨',
        'vi': 'Đã kết thúc',
    },
    'terminate deployment tips': {
        'en': 'This will terminate this running deployment and the endpoint will not no longer be accessible. Terminated deployment can be restarted and all deployment revision history are preserved.',
        'zh-CN': '这将终止正在运行的部署，并且该端点不再可访问。终止的部署可以重新启动，并且部署历史记录将保留。',
        'zh-TW': '這將終止正在運行的部署，並且該端點不再可訪問。終止的部署可以重新啟動，並且部署歷史記錄將保留。',
        'ja': 'これは、このランニング・デプロイを終了し、エンドポイントはもうアクセスできなくなります。終了したデプロイは再起動でき、すべてのデプロイのリビジョン履歴は保存されます。',
        'ko': '이것은 실행 중인 배포를 종료하고 엔드 포인트는 더 이상 접근할 수 없습니다. 종료된 배포는 다시 시작할 수 있으며 모든 배포 리비전 기록은 유지됩니다.',
        'vi': 'Điều này sẽ ngừng các giao diện đang chạy và địa chỉ IP sẽ không còn truy cập. Giao diện đã ngừng sẽ không thể khởi động lại và tất cả lịch sử bản ghi giao diện được giữ lại.',
    },
    'delete deployment tips prefix': {
        'en': 'Warning: ',
        'zh-CN': '警告：',
        'zh-TW': '警告：',
        'ja': '警告：',
        'ko': '경고: ',
        'vi': 'Cảnh báo: ',
    },
    'delete deployment tips highlight': {
        'en': 'deleting deployment will also remove all its revision history and this action is not recoverable.',
        'zh-CN': '删除部署将同时删除部署的所有历史版本，该操作不可恢复。',
        'zh-TW': '刪除部署將同時刪除部署的所有歷史版本，該操作不可復原。',
        'ja': 'デプロイの削除により、全てのリビジョン履歴が削除され、この操作は復元できません。',
        'ko': '배포 삭제는 배포의 모든 역사를 삭제하며 이 작업은 복구할 수 없습니다.',
        'vi': 'Xóa giao diện sẽ cùng xóa tất cả lịch sử bản ghi của giao diện này và hành động này không thể khôi phục.',
    },
    'delete deployment tips suffix': {
        'en': 'You can however re-create a deployment with the same name and config.',
        'zh-CN': '但是，你可以通过同样的名字和配置重新创建部署。',
        'zh-TW': '但是，你可以通過同樣的名字和配置重新創建部署。',
        'ja': 'しかし、同じ名前と設定を使用してデプロイを再作成することができます。',
        'ko': '만약에는 배포를 같은 이름과 구성으로 다시 만들 수 있습니다.',
        'vi': 'Tuy nhiên, bạn có thể tạo lại giao diện với cùng tên và cấu hình.',
    },
    'double check to be continued tips prefix': {
        'en': 'To continue, input the {{0}} name ',
        'zh-CN': '输入{{0}}名 ',
        'zh-TW': '輸入{{0}}名 ',
        'ja': '{{0}}名 ',
        'ko': '{{0}} 이름을 입력하십시오.',
        'vi': 'Để tiếp tục, nhập tên {{0}}',
    },
    'double check to be continued tips suffix': {
        'en': ' below:',
        'zh-CN': ' 以继续：',
        'zh-TW': ' 以繼續：',
        'ja': ' を入力して続行：',
        'ko': ' 아래에 입력하십시오:',
        'vi': ' để tiếp tục:',
    },
    'some days': {
        'en': '{{0}} days',
        'zh-CN': '{{0}} 天',
        'zh-TW': '{{0}} 天',
        'ja': '{{0}} 日',
        'ko': '{{0}} 일',
        'vi': '{{0}} ngày',
    },
    'no expiration': {
        'en': 'No expiration',
        'zh-CN': '不过期',
        'zh-TW': '不過期',
        'ja': '期限なし',
        'ko': '만료 없음',
        'vi': 'Không hết hạn',
    },
    'custom...': {
        'en': 'Custom...',
        'zh-CN': '自定义...',
        'zh-TW': '自定義...',
        'ja': 'カスタム...',
        'ko': '사용자 정의...',
        'vi': 'Tùy chỉnh...',
    },
    'Created At': {
        'en': 'Created At',
        'zh-CN': '创建于',
        'zh-TW': '創建於',
        'ja': '作成場所',
        'ko': '생성 일자',
        'vi': 'Ngày tạo',
    },
    'Time since Creation': {
        'en': 'Time since Creation',
        'zh-CN': '自创建以来的时间',
        'zh-TW': '自創建以來的時間',
        'ja': '創作からの時間',
        'ko': '생성 후 시간',
        'vi': 'Thời gian từ khi tạo',
    },
    'the token will expire on sth': {
        'en': 'The token will expire on {{0}}',
        'zh-CN': '令牌将在 {{0}} 过期',
        'zh-TW': '令牌將在 {{0}} 逾期',
        'ja': 'トークンは {{0}} に期限が切れます',
        'ko': '토큰은 {{0}} 만료됩니다.',
        'vi': 'Mã token sẽ hết hạn vào {{0}}',
    },
    'the token will never expire!': {
        'en': 'The token will never expire!',
        'zh-CN': '此令牌永不过期！',
        'zh-TW': '此令牌永不過期！',
        'ja': 'トークンは永久に期限がありません！',
        'ko': '토큰은 영구적으로 만료되지 않습니다!',
        'vi': 'Mã token sẽ không bao giờ hết hạn!',
    },
    'are you sure to delete this api token?': {
        'en': 'Are you sure to delete this api token?',
        'zh-CN': '你确定要删除此 API 令牌吗？',
        'zh-TW': '你確定要刪除此 API 令牌嗎？',
        'ja': 'この API トークンを削除してもよろしいですか？',
        'ko': '이 API 토큰을 삭제하시겠습니까?',
        'vi': 'Bạn có chắc chắn muốn xóa mã token này không?',
    },
    'copy command to login yatai': {
        'en': 'Authenticate with Yatai by running the following command:',
        'zh-CN': '通过运行以下命令与 Yatai 进行验证:',
        'zh-TW': '通過執行以下命令與 Yatai 進行驗證:',
        'ja': '次のコマンドを実行して、Yataiで認証します:',
        'ko': '다음 명령을 실행하여 Yatai로 인증하십시오:',
        'vi': 'Để xác thực với Yatai, hãy chạy câu lệnh sau:',
    },
    'rerun': {
        'en': 'Rerun',
        'zh-CN': '重新运行',
        'zh-TW': '重新執行',
        'ja': '再実行',
        'ko': '다시 실행',
        'vi': 'Chạy lại',
    },
    'n_bentos': {
        'en': '{{0}} Bento(s)',
        'zh-CN': '{{0}} 个 Bento',
        'zh-TW': '{{0}} 個 Bento',
        'ja': '{{0}} 個 Bento',
        'ko': '{{0}} 개 Bento',
        'vi': '{{0}} bộ Bento',
    },
    'n_versions': {
        'en': '{{0}} Version(s)',
        'zh-CN': '{{0}} 个版本',
        'zh-TW': '{{0}} 個版本',
        'ja': '{{0}} 個バージョン',
        'ko': '{{0}} 개 버전',
        'vi': '{{0}} phiên bản',
    },
    'n_deployments': {
        'en': '{{0}} Deployment(s)',
        'zh-CN': '{{0}} 个部署',
        'zh-TW': '{{0}} 個部署',
        'ja': '{{0}} 個デプロイ',
        'ko': '{{0}} 개 배포',
        'vi': '{{0}} phiên bản',
    },
    'largest': {
        'en': 'Largest',
        'zh-CN': '最大',
        'zh-TW': '最大',
        'ja': '最大',
        'ko': '최대',
        'vi': 'Lớn nhất',
    },
    'smallest': {
        'en': 'Smallest',
        'zh-CN': '最小',
        'zh-TW': '最小',
        'ja': '最小',
        'ko': '최소',
        'vi': 'Nhỏ nhất',
    },
    'latest deployments': {
        'en': 'Latest deployments',
        'zh-CN': '最新部署',
        'zh-TW': '最新部署',
        'ja': '最新のデプロイ',
        'ko': '최신 배포',
        'vi': 'Phiên bản mới nhất',
    },
    'latest versions': {
        'en': 'Latest versions',
        'zh-CN': '最新版本',
        'zh-TW': '最新版本',
        'ja': '最新のバージョン',
        'ko': '최신 버전',
        'vi': 'Phiên bản mới nhất',
    },
    'module': {
        'en': 'Module',
        'zh-CN': '模块',
        'zh-TW': '模組',
        'ja': 'モジュール',
        'ko': '모듈',
        'vi': 'Mô đun',
    },
    'label': {
        'en': 'Label',
        'zh-CN': '标签',
        'zh-TW': '標籤',
        'ja': 'ラベル',
        'ko': '라벨',
        'vi': 'Nhãn',
    },
    'context': {
        'en': 'Context',
        'zh-CN': '上下文',
        'zh-TW': '上下文',
        'ja': 'コンテキスト',
        'ko': '컨텍스트',
        'vi': 'Nội dung',
    },
    'download': {
        'en': 'Download',
        'zh-CN': '下载',
        'zh-TW': '下載',
        'ja': 'ダウンロード',
        'ko': '다운로드',
        'vi': 'Tải về',
    },
    'key': {
        'en': 'Key',
        'zh-CN': '键',
        'zh-TW': '鍵',
        'ja': 'キー',
        'ko': '키',
        'vi': 'Khóa',
    },
    'value': {
        'en': 'Value',
        'zh-CN': '值',
        'zh-TW': '值',
        'ja': '値',
        'ko': '값',
        'vi': 'Giá trị',
    },
    'recent activities': {
        'en': 'Recent Activities',
        'zh-CN': '最近活动',
        'zh-TW': '最近活動',
        'ja': '最近の活動',
        'ko': '최근 활동',
        'vi': 'Hoạt động gần đây',
    },
    'pushed': {
        'en': 'Pushed',
        'zh-CN': '推送',
        'zh-TW': '推送',
        'ja': 'プッシュ',
        'ko': '배포',
        'vi': 'Đã được đẩy',
    },
    'active deployments': {
        'en': 'Active Deployments',
        'zh-CN': '活动部署',
        'zh-TW': '活動部署',
        'ja': 'アクティブなデプロイ',
        'ko': '활성 배포',
        'vi': 'Phiên bản đang hoạt động',
    },
    'revisions': {
        'en': 'Revisions',
        'zh-CN': '版本列表',
        'zh-TW': '版本列表',
        'ja': 'リビジョン',
        'ko': '리비전',
        'vi': 'Phiên bản',
    },
    'labels': {
        'en': 'Labels',
        'zh-CN': '标签列表',
        'zh-TW': '標籤列表',
        'ja': 'ラベル',
        'ko': '라벨',
        'vi': 'Nhãn',
    },
    'models': {
        'en': 'Models',
        'zh-CN': '模型列表',
        'zh-TW': '模組列表',
        'ja': 'モデル',
        'ko': '모델',
        'vi': 'Mô hình',
    },
    'bentos': {
        'en': 'Bentos',
        'zh-CN': 'Bentos',
        'zh-TW': 'Bentos',
        'ja': 'Bentos',
        'ko': 'Bentos',
        'vi': 'Bentos',
    },
    'deployments': {
        'en': 'Deployments',
        'zh-CN': '部署列表',
        'zh-TW': '部署列表',
        'ja': 'デプロイ',
        'ko': '배포',
        'vi': 'Phiên bản',
    },
    'model repositories': {
        'en': 'Model Repositories',
        'zh-CN': '模型仓库列表',
        'zh-TW': '模組倉庫列表',
        'ja': 'モデルリポジトリ',
        'ko': '모델 저장소',
        'vi': 'Kho chứa mô hình',
    },
    'bento repositories': {
        'en': 'Bento Repositories',
        'zh-CN': 'Bento 仓库列表',
        'zh-TW': 'Bento 倉庫列表',
        'ja': 'Bento リポジトリ',
        'ko': 'Bento 저장소',
        'vi': 'Kho chứa Bento',
    },
    'users': {
        'en': 'Users',
        'zh-CN': '用户列表',
        'zh-TW': '用戶列表',
        'ja': 'ユーザー',
        'ko': '사용자',
        'vi': 'Người dùng',
    },
    'members': {
        'en': 'Members',
        'zh-CN': '成员列表',
        'zh-TW': '成員列表',
        'ja': 'メンバー',
        'ko': '멤버',
        'vi': 'Thành viên',
    },
    'organizations': {
        'en': 'Organizations',
        'zh-CN': '组织列表',
        'zh-TW': '組織列表',
        'ja': '組織',
        'ko': '조직',
        'vi': 'Tổ chức',
    },
    'clusters': {
        'en': 'Clusters',
        'zh-CN': '集群列表',
        'zh-TW': '集群列表',
        'ja': 'クラスター',
        'ko': '클러스터',
        'vi': 'Nhóm',
    },
    'yatai components': {
        'en': 'Yatai Components',
        'zh-CN': 'Yatai 组件列表',
        'zh-TW': 'Yatai 組件列表',
        'ja': 'Yatai コンポーネント',
        'ko': 'Yatai 구성 요소',
        'vi': 'Yatai thành phần',
    },
    'deployment targets': {
        'en': 'Deployment Targets',
        'zh-CN': '部署目标列表',
        'zh-TW': '部署目標列表',
        'ja': 'デプロイターゲット',
        'ko': '배포 대상',
        'vi': 'Mục tiêu phân phối',
    },
    'api tokens': {
        'en': 'API Tokens',
        'zh-CN': 'API 令牌列表',
        'zh-TW': 'API 令牌列表',
        'ja': 'API トークン',
        'ko': 'API 토큰',
        'vi': 'API mã',
    },
    'documentation': {
        'en': 'Documentation',
        'zh-CN': '文档',
        'zh-TW': '文档',
        'ja': 'ドキュメント',
        'ko': '문서',
        'vi': 'Tài liệu',
    },
    'release notes': {
        'en': 'Release Notes',
        'zh-CN': '发布说明',
        'zh-TW': '發布說明',
        'ja': 'リリースノート',
        'ko': '릴리즈 노트',
        'vi': 'Ghi chú phát hành',
    },
    'blog posts': {
        'en': 'Blog Posts',
        'zh-CN': '博客文章',
        'zh-TW': '博客文章',
        'ja': 'ブログ記事',
        'ko': '블로그 게시물',
        'vi': 'Bài viết blog',
    },
    'contact': {
        'en': 'Contact',
        'zh-CN': '联系',
        'zh-TW': '聯繫',
        'ja': 'お問い合わせ',
        'ko': '연락',
        'vi': 'Liên hệ',
    },
    'docs': {
        'en': 'Docs',
        'zh-CN': '文档',
        'zh-TW': '文档',
        'ja': 'ドキュメント',
        'ko': '문서',
        'vi': 'Tài liệu',
    },
    'community': {
        'en': 'Community',
        'zh-CN': '社区',
        'zh-TW': '社群',
        'ja': 'コミュニティ',
        'ko': '커뮤니티',
        'vi': 'Cộng đồng',
    },
    'the events I created': {
        'en': 'The events I created',
        'zh-CN': '我创建的事件',
        'zh-TW': '我創建的事件',
        'ja': '私が作成したイベント',
        'ko': '내가 만든 이벤트',
        'vi': 'Sự kiện tôi tạo',
    },
    'oldest create': {
        'en': 'Oldest create',
        'zh-CN': '最早创建',
        'zh-TW': '最早創建',
        'ja': '最初に作成',
        'ko': '최초 생성',
        'vi': 'Tạo nhất',
    },
    'newest create': {
        'en': 'Newest create',
        'zh-CN': '最新创建',
        'zh-TW': '最新創建',
        'ja': '最新に作成',
        'ko': '최신 생성',
        'vi': 'Tạo mới nhất',
    },
    'resource type': {
        'en': 'Resource Type',
        'zh-CN': '资源类型',
        'zh-TW': '資源類型',
        'ja': 'リソースタイプ',
        'ko': '자원 유형',
        'vi': 'Loại tài nguyên',
    },
    'operation name': {
        'en': 'Operation Name',
        'zh-CN': '操作名称',
        'zh-TW': '操作名稱',
        'ja': '操作名',
        'ko': '작업 이름',
        'vi': 'Tên thao tác',
    },
    'the default kube namespace for deployments': {
        'en': 'The default k8s namespace for deployments',
        'zh-CN': '部署的默认 k8s 命名空间',
        'zh-TW': '部署的默認 k8s 命名空間',
        'ja': 'デプロイのデフォルトの k8s ネームスペース',
        'ko': '배포에 대한 기본 k8s 네임스페이스',
        'vi': 'Không gian k8s mặc định cho phân phối',
    },
    'new deployment': {
        'en': 'New Deployment',
        'zh-CN': '新建部署',
        'zh-TW': '新增部署',
        'ja': '新規デプロイ',
        'ko': '새 배포',
        'vi': 'Phân phối mới',
    },
    'update deployment': {
        'en': 'Update Deployment',
        'zh-CN': '更新部署',
        'zh-TW': '更新部署',
        'ja': 'デプロイの更新',
        'ko': '배포 업데이트',
        'vi': 'Cập nhật phân phối',
    },
    'deployment name': {
        'en': 'Deployment Name',
        'zh-CN': '部署名称',
        'zh-TW': '部署名稱',
        'ja': 'デプロイ名',
        'ko': '배포 이름',
        'vi': 'Tên phân phối',
    },
    'select bento': {
        'en': 'Select Bento',
        'zh-CN': '选择 Bento',
        'zh-TW': '選擇 Bento',
        'ja': 'ベントを選択',
        'ko': 'bento를 선택하십시오',
        'vi': 'Chọn Bento',
    },
    'configurations': {
        'en': 'Configurations',
        'zh-CN': '配置',
        'zh-TW': '配置',
        'ja': '設定',
        'ko': '설정값',
        'vi': 'Cấu hình',
    },
    'min': {
        'en': 'Min',
        'zh-CN': '最小',
        'zh-TW': '最小',
        'ja': '最小',
        'ko': '최소',
        'vi': 'Tối thiểu',
    },
    'max': {
        'en': 'Max',
        'zh-CN': '最大',
        'zh-TW': '最大',
        'ja': '最大',
        'ko': '최대',
        'vi': 'Tối đa',
    },
    'number of replicas': {
        'en': 'Number of replicas',
        'zh-CN': '副本数',
        'zh-TW': '副本數',
        'ja': 'レプリカ数',
        'ko': '레플리카 수',
        'vi': 'Số lượng bản sao',
    },
    'resource per replicas': {
        'en': 'Resource per replicas',
        'zh-CN': '副本资源',
        'zh-TW': '副本資源',
        'ja': 'レプリカ資源',
        'ko': 'Resource per replicas',
        'vi': 'Tài nguyên cho mỗi bản sao',
    },
    'environment variables': {
        'en': 'Environment variables',
        'zh-CN': '环境变量',
        'zh-TW': '環境變數',
        'ja': '環境変数',
        'ko': '환경 변수',
        'vi': 'Biến toàn cục',
    },
    'no deployment found': {
        'en': 'No deployment found',
        'zh-CN': '没有找到部署',
        'zh-TW': '沒有找到部署',
        'ja': 'デプロイが見つかりません',
        'ko': '배포를 찾을 수 없습니다',
        'vi': 'Không tìm thấy phân phối',
    },
    'edit': {
        'en': 'Edit',
        'zh-CN': '编辑',
        'zh-TW': '編輯',
        'ja': '編集',
        'ko': '편집',
        'vi': 'Chỉnh sửa',
    },
    'n deployments': {
        'en': '{{0}} deployments',
        'zh-CN': '{{0}} 个部署',
        'zh-TW': '{{0}} 個部署',
        'ja': '{{0}} 个のデプロイ',
        'ko': '{{0}} 개의 배포',
        'vi': '{{0}} phân phối',
    },
    'n bentos': {
        'en': '{{0}} Bentos',
        'zh-CN': '{{0}} 个 Bento',
        'zh-TW': '{{0}} 個 Bento',
        'ja': '{{0}} 个 Bento',
        'ko': '{{0}} Bento',
        'vi': '{{0}} Bento',
    },
    'Follow to [BentoML quickstart guide] to create your first Bento. prefix': {
        'en': 'Follow to ',
        'zh-CN': '查看 ',
        'zh-TW': '查看 ',
        'ja': '',
        'ko': '',
        'vi': 'Theo dõi ',
    },
    'BentoML quickstart guide': {
        'en': 'BentoML quickstart guide',
        'zh-CN': 'BentoML 快速入门指南',
        'zh-TW': 'BentoML 快速入門指南',
        'ja': 'BentoML クイックスタートガイド',
        'ko': 'BentoML 퀵스타트 가이드',
        'vi': 'BentoML hướng dẫn nhanh',
    },
    'Follow to [BentoML quickstart guide] to create your first Bento. suffix': {
        'en': ' to create your first Bento.',
        'zh-CN': ' 创建你的第一个 Bento。',
        'zh-TW': ' 創建你的第一個 Bento。',
        'ja': ' を参照して、あなたの最初のベントを作成します。',
        'ko': '를 따라서 첫 번째 bento를 만듭니다.',
        'vi': ' để tạo ra bản gốc của bạn đầu tiên.',
    },
    'Create an [API-token] and login your BentoML CLI. prefix': {
        'en': 'Create an ',
        'zh-CN': '创建一个 ',
        'zh-TW': '創建一個 ',
        'ja': '',
        'ko': '',
        'vi': 'Tạo một ',
    },
    'API-token': {
        'en': 'API-token',
        'zh-CN': 'API-token',
        'zh-TW': 'API-token',
        'ja': 'APIトークン',
        'ko': 'API 토큰',
        'vi': 'API-token',
    },
    'Create an [API-token] and login your BentoML CLI. suffix': {
        'en': ' and login your BentoML CLI.',
        'zh-CN': ' 并登录你的 BentoML CLI。',
        'zh-TW': ' 和登錄你的 BentoML CLI。',
        'ja': ' とログインします。',
        'ko': ' 및 BentoML CLI로 로그인합니다.',
        'vi': ' và đăng nhập vào CLI của BentoML.',
    },
    'Push new Bento to Yatai with the `bentoml push` CLI command. prefix': {
        'en': 'Push new Bento to Yatai with the ',
        'zh-CN': '使用 ',
        'zh-TW': '使用 ',
        'ja': '',
        'ko': '',
        'vi': 'Đăng bản gốc mới vào Yatai bằng lệnh CLI ',
    },
    'Push new Bento to Yatai with the `bentoml push` CLI command. suffix': {
        'en': ' CLI command.',
        'zh-CN': ' 命令将新的 Bento 推送到 Yatai。',
        'zh-TW': ' 命令將新的 Bento 推送到 Yatai。',
        'ja': ' コマンドで新しいベントを Yatai にプッシュします。',
        'ko': ' 명령어로 새 Bento를 Yatai에 Push 합니다.',
        'vi': '.',
    },
    'Follow to [BentoML quickstart guide] to create your first Model. prefix': {
        'en': 'Follow to ',
        'zh-CN': '查看 ',
        'zh-TW': '查看 ',
        'ja': '',
        'ko': '',
        'vi': 'Theo dõi ',
    },
    'Follow to [BentoML quickstart guide] to create your first Model. suffix': {
        'en': ' to create your first Model.',
        'zh-CN': ' 创建你的第一个模型。',
        'zh-TW': ' 創建你的第一個模型。',
        'ja': ' を参照して、あなたの最初のモデルを作成します。',
        'ko': '를 따라서 첫 번째 모델을 만듭니다.',
        'vi': ' để tạo ra bản gốc của bạn đầu tiên.',
    },
    'Push new Model to Yatai with the `bentoml models push` CLI command. prefix': {
        'en': 'Push new Model to Yatai with the ',
        'zh-CN': '使用 ',
        'zh-TW': '使用 ',
        'ja': '',
        'ko': '',
        'vi': 'Đăng bản gốc mới vào Yatai bằng lệnh CLI ',
    },
    'Push new Model to Yatai with the `bentoml models push` CLI command. suffix': {
        'en': ' CLI command.',
        'zh-CN': ' 命令将新的模型推送到 Yatai。',
        'zh-TW': ' 命令將新的模型推送到 Yatai。',
        'ja': ' コマンドで新しいモデルを Yatai にプッシュします。',
        'ko': ' 명령어로 새 모델을 Yatai에 Push 합니다.',
        'vi': '.',
    },
    'kube namespace': {
        'en': 'Kubernetes Namespace',
        'zh-CN': 'Kubernetes 命名空间',
        'zh-TW': 'Kubernetes 命名空間',
        'ja': 'Kubernetes の名前空間',
        'ko': 'Kubernetes 네임스페이스',
        'vi': 'Kubernetes Namespace',
    },
    'first_name': {
        'en': 'First Name',
        'zh-CN': '名',
        'zh-TW': '名',
        'ja': '名',
        'ko': '이름',
        'vi': 'Tên',
    },
    'last_name': {
        'en': 'Last Name',
        'zh-CN': '姓',
        'zh-TW': '姓',
        'ja': '姓',
        'ko': '성',
        'vi': 'Họ',
    },
    'email': {
        'en': 'email',
        'zh-CN': '邮箱',
        'zh-TW': '郵箱',
        'ja': 'メールアドレス',
        'ko': '이메일',
        'vi': 'email',
    },
    'assign user to role': {
        'en': 'Assign user to role',
        'zh-CN': '分配用户到角色',
        'zh-TW': '分配用戶到角色',
        'ja': 'ユーザーをロールに割り当てる',
        'ko': '사용자를 역할에 할당하십시오',
        'vi': 'Gán người dùng vào vai trò',
    },
    'create new user': {
        'en': 'Create new user',
        'zh-CN': '创建新用户',
        'zh-TW': '創建新用戶',
        'ja': '新しいユーザーを作成する',
        'ko': '새 사용자 만들기',
        'vi': 'Tạo người dùng mới',
    },
    'assign user roles': {
        'en': 'Assign user roles',
        'zh-CN': '分配用户角色',
        'zh-TW': '分配用戶角色',
        'ja': 'ユーザーのロールを割り当てる',
        'ko': '사용자 역할 할당',
        'vi': 'Gán vai trò cho người dùng',
    },
    'edit user role': {
        'en': 'Edit user role',
        'zh-CN': '编辑用户角色',
        'zh-TW': '編輯用戶角色',
        'ja': 'ユーザーのロールを編集',
        'ko': '사용자 역할 편집',
        'vi': 'Chỉnh sửa vai trò người dùng',
    },
    'deactivate': {
        'en': 'Deactivate',
        'zh-CN': '停用',
        'zh-TW': '停用',
        'ja': '停止',
        'ko': '중지',
        'vi': 'Tắt',
    },
    'active': {
        'en': 'Active',
        'zh-CN': '激活',
        'zh-TW': '激活',
        'ja': '有効',
        'ko': '활성',
        'vi': 'Hoạt động',
    },
    'deactivated': {
        'en': 'Deactivated',
        'zh-CN': '已停用',
        'zh-TW': '已停用',
        'ja': '停止',
        'ko': '중지됨',
        'vi': 'Đã tắt',
    },
    'assigned new role': {
        'en': 'Assigned new role',
        'zh-CN': '分配新角色',
        'zh-TW': '分配新角色',
        'ja': '新しいロールを割り当てる',
        'ko': '새 역할 할당',
        'vi': 'Gán vai trò mới',
    },
    'created new user': {
        'en': 'Created new user',
        'zh-CN': '创建新用户',
        'zh-TW': '創建新用戶',
        'ja': '新しいユーザーを作成',
        'ko': '새 사용자 만들기',
        'vi': 'Tạo người dùng mới',
    },
    'password changed': {
        'en': 'Password changed',
        'zh-CN': '密码已更改',
        'zh-TW': '密碼已更改',
        'ja': 'パスワードが変更されました',
        'ko': '비밀번호 변경',
        'vi': 'Mật khẩu đã được thay đổi',
    },
    'password not match': {
        'en': 'Password not match',
        'zh-CN': '密码不匹配',
        'zh-TW': '密碼不匹配',
        'ja': 'パスワードが一致しません',
        'ko': '비밀번호가 일치하지 않습니다',
        'vi': 'Mật khẩu không khớp',
    },
    'change password': {
        'en': 'Change password',
        'zh-CN': '更改密码',
        'zh-TW': '更改密碼',
        'ja': 'パスワードを変更',
        'ko': '비밀번호 변경',
        'vi': 'Thay đổi mật khẩu',
    },
    'new password': {
        'en': 'New password',
        'zh-CN': '新密码',
        'zh-TW': '新密碼',
        'ja': '新しいパスワード',
        'ko': '새 비밀번호',
        'vi': 'Mật khẩu mới',
    },
    'confirm password': {
        'en': 'Confirm password',
        'zh-CN': '确认密码',
        'zh-TW': '確認密碼',
        'ja': 'パスワードを確認',
        'ko': '비밀번호 확인',
        'vi': 'Xác nhận mật khẩu',
    },
    'current password': {
        'en': 'Current password',
        'zh-CN': '当前密码',
        'zh-TW': '當前密碼',
        'ja': '現在のパスワード',
        'ko': '현재 비밀번호',
        'vi': 'Mật khẩu hiện tại',
    },
    'setup initial admin account': {
        'en': 'Setup initial admin account',
        'zh-CN': '设置初始管理员账户',
        'zh-TW': '設置初始管理員賬戶',
        'ja': '初期管理者アカウントを設定する',
        'ko': '초기 관리자 계정 설정',
        'vi': 'Thiết lập tài khoản quản trị đầu tiên',
    },
    'get the initialization token from command': {
        'en': 'Get the initialization token from command',
        'zh-CN': '从命令中获取初始化令牌',
        'zh-TW': '從命令中獲取初始化令牌',
        'ja': 'コマンドから初期化トークンを取得する',
        'ko': '명령어로 초기화 토큰 가져오기',
        'vi': 'Lấy mã token khởi tạo từ lệnh',
    },
    'already the latest version': {
        'en': 'Already the latest version',
        'zh-CN': '已经是最新版本',
        'zh-TW': '已經是最新版本',
        'ja': '最新バージョンです',
        'ko': '이미 최신 버전입니다',
        'vi': 'Đã là phiên bản mới nhất',
    },
    'group': {
        'en': 'Group',
        'zh-CN': '组',
        'zh-TW': '群組',
        'ja': 'グループ',
        'ko': '그룹',
        'vi': 'Nhóm',
    },
    'component': {
        'en': 'Component',
        'zh-CN': '组件',
        'zh-TW': '組件',
        'ja': 'コンポーネント',
        'ko': '구성 요소',
        'vi': 'Thành phần',
    },
    'endpoint public access': {
        'en': 'Endpoint public access',
        'zh-CN': '端点公开访问',
        'zh-TW': '端點公開訪問',
        'ja': 'エンドポイントの公開アクセス',
        'ko': '공개된(public access) 엔드포인트',
        'vi': 'Truy cập công khai',
    },
    'cluster internal url': {
        'en': 'Cluster internal URL',
        'zh-CN': '集群内部 URL',
        'zh-TW': '集群內部 URL',
        'ja': 'クラスタ内部 URL',
        'ko': '클러스터 내부 URL',
        'vi': 'URL nội bộ',
    },
    'accessing endpoint': {
        'en': 'Accessing Endpoint',
        'zh-CN': '访问端点',
        'zh-TW': '訪問端點',
        'ja': 'エンドポイントにアクセス',
        'ko': '엔드포인트에 접근',
        'vi': 'Truy cập',
    },
    'accessing endpoint modal content piece 1': {
        'en': 'This deployment is configured with',
        'zh-CN': '此部署配置了',
        'zh-TW': '此部置配置了',
        'ja': 'このデプロイメントは',
        'ko': '이 배포만큼',
        'vi': 'Đã cấu hình với',
    },
    'accessing endpoint modal content piece 2': {
        'en': 'which means the endpoint can only be accessed from with-in the cluster.',
        'zh-CN': '这意味着端点只能在集群内被访问。',
        'zh-TW': '這意味著端點只能在集群內被訪問。',
        'ja': 'によってエンドポイントはクラスタ内だけにアクセスできます。',
        'ko': '에서만 접근할 수 있습니다.',
        'vi': 'đồng nghĩa là endpoint chỉ có thể được truy cập từ trong cluster.',
    },
    'accessing endpoint modal content piece 3': {
        'en': 'If you need access to the endpoint for testing and debugging, you will need direct access to the cluster. Assuming you have',
        'zh-CN': '如果您需要访问端点以进行测试和调试，您需要直接访问集群。假设您有',
        'zh-TW': '如果您需要訪問端點以進行測試和調試，您需要直接訪問集群。假設您有',
        'ja': 'テストやデバッグのためにエンドポイントにアクセスする必要がある場合、クラスタに直接アクセスする必要があります。',
        'ko': '테스트 및 디버깅을 위해 엔드포인트에 접근해야 할 경우, 클러스터에 직접 접근해야 합니다. 아래에서',
        'vi': 'Nếu bạn cần truy cập endpoint để thực hiện test và debug, bạn sẽ cần truy cập trực tiếp vào cluster. Với tư cách',
    },
    'accessing endpoint modal content piece 4': {
        'en': 'installed and configured with the target cluster, you can create a local proxy that forwards requests to the deployment endpoint in cluster with the following command',
        'zh-CN': '已安装和配置了目标集群，您可以创建一个本地代理，它用以下命令将请求转发到集群中的部署端点',
        'zh-TW': '已安裝和配置了目標集群，您可以創建一個本地代理，它用以下命令將請求轉發到集群中的部署端點',
        'ja': 'インストールと設定されたクラスタにあるデプロイメントのエンドポイントにアクセスするために、以下のコマンドを使用して、クラスタ内のデプロイメントのエンドポイントへのリクエストを転送することができます。',
        'ko': '설치 및 설정된 타겟 클러스터에서, 다음 명령어를 사용하여 다음과 같이 로컬 프록시를 만들 수 있습니다',
        'vi': 'Bạn có thể tạo proxy để chuyển tiếp yêu cầu đến endpoint trong cluster với các lệnh sau',
    },
    'accessing endpoint modal content piece 5': {
        'en': 'Now visit browser at, or send test requests to',
        'zh-CN': '现在访问浏览器，或者发送测试请求到',
        'zh-TW': '現在訪問瀏覽器，或者發送測試請求到',
        'ja': '以下のURLまたはテストリクエストを送信してください。',
        'ko': '지금 브라우저에서 방문하거나, 다음 요청을 보내십시오',
        'vi': 'Bây giờ truy cập trình duyệt tại, hoặc gửi yêu cầu test tới',
    },
    'accessing endpoint modal content piece 6': {
        'en': 'to test out the endpoint.',
        'zh-CN': '来测试端点。',
        'zh-TW': '來測試端點。',
        'ja': 'エンドポイントをテストしてください。',
        'ko': '엔드포인트를 테스트하십시오.',
        'vi': 'để thực hiện test endpoint.',
    },
    'enabled': {
        'en': 'Enabled',
        'zh-CN': '已启用',
        'zh-TW': '已啟用',
        'ja': '有効',
        'ko': '사용 중',
        'vi': 'Đã kích hoạt',
    },
    'disabled': {
        'en': 'Disabled',
        'zh-CN': '已禁用',
        'zh-TW': '已禁用',
        'ja': '無効',
        'ko': '사용 중지',
        'vi': 'Đã tắt',
    },
    'endpoint public access enable piece 1': {
        'en': 'Public endpoint access enabled.',
        'zh-CN': '公共端点访问已启用。',
        'zh-TW': '公共端點訪問已啟用。',
        'ja': '公共端点アクセスが有効になりました。',
        'ko': '공용 엔드 포인트 접근이 가능합니다',
        'vi': 'Truy cập của đầu vào đã được kích hoạt.',
    },
    'warning': {
        'en': 'Warning',
        'zh-CN': '警告',
        'zh-TW': '警告',
        'ja': '警告',
        'ko': '경고',
        'vi': 'Cảnh báo',
    },
    'endpoint public access enable piece 2': {
        'en': 'Yatai will create a unique URL for the deployment endpoint, which is publically accessible from outside of the cluster. Related ingress resources will also be created in the target cluster.',
        'zh-CN':
            'Yatai 将创建一个唯一的 URL 来访问部署端点，该端点将在集群外部可访问。相关的 ingress 资源也将在目标集群中创建。',
        'zh-TW':
            'Yatai 會創建一個唯一的 URL 来訪問部署端點，該端點將在集群外部可訪問。相關的 ingress 資源也將在目標集群中創建。',
        'ja': 'Yatai は、クラスタ外部からアクセス可能な公共端点を作成します。',
        'ko': 'Yatai 는 배포 엔드 포인트에 접근할 유니크한 URL을 만듭니다. 이 URL은 클러스터 외부에서 접근 가능합니다.',
        'vi': 'Yatai sẽ tạo một URL duy nhất để truy cập đầu vào, đó sẽ được truy cập từ bên ngoài của hệ thống. Người dùng sẽ có thể truy cập đến đầu vào này từ bên ngoài hệ thống.',
    },
    'endpoint public access disable piece 1': {
        'en': 'Public endpoint access disabled. Yatai will create a Kubernetes Service for the deployment which can only be accessed from within the target cluster. If you have direct access to the cluster, you may use',
        'zh-CN':
            '公共端点访问已禁用。Yatai 将创建一个 Kubernetes 服务来访问部署端点，该端点将在集群内部可访问。如果您有直接访问集群的权限，您可以使用',
        'zh-TW':
            '公共端點訪問已禁用。Yatai 會創建一個 Kubernetes 服務來訪問部署端點，該端點將在集群內部可訪問。如果您有直接訪問集群的權限，您可以使用',
        'ja': '公共端点アクセスが無効になりました。Yatai は、クラスタ内部からアクセス可能な公共端点を作成します。',
        'ko': '공용 엔드포인트 접근이 불가합니다. Yatai는 대상 클러스터(target cluster)에서만 접근 가능한 Kubernetes Deployment를 생성합니다. 단 클러스터에 직접 접근이 가능하다면, 사용가능합니다',
        'vi': 'Truy cập đầu vào đã bị tắt. Yatai sẽ tạo một dịch vụ Kubernetes để truy cập đầu vào, đó sẽ được truy cập từ bên trong của hệ thống. Nếu bạn có truy cập trực tiếp đến hệ thống, bạn có thể sử dụng',
    },
    'endpoint public access disable piece 2': {
        'en': 'for testing the endpoint locally.',
        'zh-CN': '来测试端点。',
        'zh-TW': '來測試端點。',
        'ja': 'をテストすることができます。',
        'ko': '에서 엔드 포인트를 시뮬레이션합니다.',
        'vi': 'để kiểm tra đầu vào trực tiếp.',
    },
    'Only the administrator has permission to create deployments, please contact the administrator': {
        'en': 'Only the administrator has permission to create deployments, please contact the administrator',
        'zh-CN': '只有管理员有权创建部署，请联系管理员',
        'zh-TW': '只有管理員有權創建部署，請聯繫管理員',
        'ja': '管理者のみがデプロイを作成することができます。管理者にお問い合わせください。',
        'ko': 'Only the administrator has permission to create deployments, please contact the administrator',
        'vi': 'Chỉ có quyền quản trị viên mới có thể tạo bản đồ, vui lòng liên hệ quản trị viên',
    },
    'Only the administrator has permission to operate deployments, please contact the administrator': {
        'en': 'Only the administrator has permission to operate deployments, please contact the administrator',
        'zh-CN': '只有管理员有权操作部署，请联系管理员',
        'zh-TW': '只有管理員有權操作部署，請聯繫管理員',
        'ja': '管理者のみがデプロイを操作することができます。管理者にお問い合わせください。',
        'ko': '관리지만이 배포 수행 권한을 가지고 있습니다. 관리자에게 문의하십시오',
        'vi': 'Chỉ có quyền quản trị viên mới có thể thao tác bản đồ, vui lòng liên hệ quản trị viên',
    },
    'resource config': {
        'en': 'Resource Config',
        'zh-CN': '资源配置',
        'zh-TW': '資源配置',
        'ja': 'リソース設定',
        'ko': '자원 구성',
        'vi': 'Cấu hình nguồn',
    },
    'docker image builder pods': {
        'en': 'Docker Image Builder Pods',
        'zh-CN': 'Docker 镜像构建 Pod',
        'zh-TW': 'Docker 鏡像構建 Pod',
        'ja': 'Docker イメージビルダーのポッド',
        'ko': 'Docker Image Builder Pods',
        'vi': 'Pods của Docker Image Builder',
    },
    'you need to install yatai-deployment component to enable deployment function': {
        'en': 'You need to install yatai-deployment component to enable deployment function',
        'zh-CN': '您需要安装 yatai-deployment 组件才能启用部署功能',
        'zh-TW': '您需要安裝 yatai-deployment 組件才能啟用部署功能',
        'ja': 'デプロイ機能を有効にするには、yatai-deployment コンポーネントをインストールする必要があります。',
        'ko': '배포 기능을 사용하려면 yatai-deployment 컴포넌트를 설치해야합니다.',
        'vi': 'Bạn cần cài đặt yatai-deployment cấu trúc để kích hoạt chức năng bản đồ',
    },
    'created': {
        'en': 'Created',
        'zh-CN': '创建了',
        'zh-TW': '創建了',
        'ja': '作成しました',
        'ko': '생성됨',
        'vi': 'Đã tạo',
    },
    'no active deployments': {
        'en': 'No active deployments',
        'zh-CN': '没有激活的部署',
        'zh-TW': '沒有激活的部署',
        'ja': 'アクティブなデプロイはありません',
        'ko': '아직 배포된 적이 없음',
        'vi': 'Không có bản đồ nào hoạt động',
    },
    'no events': {
        'en': 'No events',
        'zh-CN': '没有事件',
        'zh-TW': '沒有事件',
        'ja': 'イベントはありません',
        'ko': '이벤트 없음',
        'vi': 'Không có sự kiện',
    },
    'deleted': {
        'en': 'Deleted',
        'zh-CN': '已删除',
        'zh-TW': '已刪除',
        'ja': '削除しました',
        'ko': '삭제됨',
        'vi': 'Đã xóa',
    },
    'loading...': {
        'en': 'Loading...',
        'zh-CN': '加载中...',
        'zh-TW': '加載中...',
        'ja': '読み込み中...',
        'ko': '로딩 중...',
        'vi': 'Đang tải...',
    },
    'custom resources requests': {
        'en': 'Custom Resources Requests',
        'zh-CN': '自定义资源请求',
        'zh-TW': '自定義資源請求',
        'ja': 'カスタムリソースのリクエスト',
        'ko': '사용자 정의 자원 요청',
        'vi': 'Yêu cầu tài nguyên tùy chỉnh',
    },
    'custom resources limits': {
        'en': 'Custom Resources Limits',
        'zh-CN': '自定义资源限制',
        'zh-TW': '自定義資源限制',
        'ja': 'カスタムリソースの制限',
        'ko': '사용자 정의 자원 제한',
        'vi': 'Giới hạn tài nguyên tùy chỉnh',
    },
    'bentoml configuration': {
        'zh-CN': 'BentoML 配置',
        'zh-TW': 'BentoML 配置',
        'en': 'BentoML Configuration',
        'ja': 'BentoML Configuration',
        'ko': 'BentoML Configuration',
        'vi': 'BentoML Configuration',
    },
    'pod {{0}} is not exists': {
        'en': 'Pod {{0}} is not exists',
        'zh-CN': 'Pod {{0}} 不存在',
        'zh-TW': 'Pod {{0}} 不存在',
        'ja': 'Pod {{0}} は存在しません',
        'ko': 'Pod {{0}} 이 존재하지 않습니다',
        'vi': 'Pod {{0}} không tồn tại',
    },
    'show file manager': {
        'en': 'Show File Manager',
        'zh-CN': '显示文件管理器',
        'zh-TW': '顯示文件管理器',
        'ja': 'ファイルマネージャーを表示',
        'ko': '파일 관리자 표시',
        'vi': 'Hiển thị trình quản lý tập tin',
    },
    'hide file manager': {
        'en': 'Hide File Manager',
        'zh-CN': '隐藏文件管理器',
        'zh-TW': '隱藏文件管理器',
        'ja': 'ファイルマネージャーを隠す',
        'ko': '파일 관리자 숨기기',
        'vi': 'Ẩn trình quản lý tập tin',
    },
    'file size cannot exceed': {
        'en': 'File size cannot exceed {{0}}',
        'zh-CN': '文件大小不能超过 {{0}}',
        'zh-TW': '文件大小不能超過 {{0}}',
        'ja': 'ファイルサイズは {{0}} を超えることはできません',
        'ko': '파일 크기는 {{0}}을(를) 초과할 수 없습니다',
        'vi': 'Kích thước tập tin không được vượt quá {{0}}',
    },
    'upload file failed': {
        'en': 'Upload file failed',
        'zh-CN': '上传文件失败',
        'zh-TW': '上傳文件失敗',
        'ja': 'ファイルのアップロードに失敗しました',
        'ko': '파일 업로드 실패',
        'vi': 'Tải tập tin thất bại',
    },
    'upload sth successfully': {
        'en': 'Upload file {{0}} successfully',
        'zh-CN': '上传文件 {{0}} 成功',
        'zh-TW': '上傳文件 {{0}} 成功',
        'ja': 'ファイル {{0}} をアップロードしました',
        'ko': '파일 {{0}} 업로드 성공',
        'vi': 'Tải tập tin {{0}} thành công',
    },
    'click or drop file to this section': {
        'en': 'Click or drop file to this section',
        'zh-CN': '点击或拖拽文件到此区域',
        'zh-TW': '點擊或拖拽文件到此區域',
        'ja': 'クリックまたはファイルをこのセクションにドロップします',
        'ko': '클릭하거나 파일을 이 섹션에 드롭하십시오',
        'vi': 'Nhấp ho đặt tập tin vào phần này',
    },
    'upload file to pod tips': {
        'en': 'The file will be uploaded to /tmp/yatai_upload directory',
        'zh-CN': '文件将会上传到 /tmp/yatai_upload 目录',
        'zh-TW': '文件將會上傳到 /tmp/yatai_upload 目錄',
        'ja': 'ファイルは /tmp/yatai_upload ディレクトリにアップロードされます',
        'ko': '파일은 /tmp/yatai_upload 디렉토리에 업로드됩니다',
        'vi': 'Tập tin sẽ được tải lên thư mục /tmp/yatai_upload',
    },
    'please input the absolute path in the container': {
        'en': 'Please input the absolute path in the container',
        'zh-CN': '请输入 Container 中的绝对路径',
        'zh-TW': '請輸入 Container 中的絕對路徑',
        'ja': 'コンテナ内の絶対パスを入力してください',
        'ko': '컨테이너 내의 절대 경로를 입력하십시오',
        'vi': 'Vui lòng nhập đường dẫn tuyệt đối trong container',
    },
    'upload file': {
        'en': 'Upload File',
        'zh-CN': '上传文件',
        'zh-TW': '上傳文件',
        'ja': 'ファイルをアップロード',
        'ko': '파일 업로드',
        'vi': 'Tải tập tin',
    },
    'download file': {
        'en': 'Download File',
        'zh-CN': '下载文件',
        'zh-TW': '下載文件',
        'ja': 'ファイルをダウンロード',
        'ko': '파일 다운로드',
        'vi': 'Tải tập tin',
    },
    'file path': {
        'en': 'File Path',
        'zh-CN': '文件路径',
        'zh-TW': '文件路徑',
        'ja': 'ファイルパス',
        'ko': '파일 경로',
        'vi': 'Đường dẫn tập tin',
    },
    'enable debug mode': {
        'en': 'Enable debug mode',
        'zh-CN': '开启调试模式',
        'zh-TW': '開啟調試模式',
        'ja': 'デバッグモードを有効にする',
        'ko': '디버그 모드 활성화',
        'vi': 'Bật chế độ gỡ lỗi',
    },
    'enable debug mode desc': {
        'zh-CN':
            '开启调试模式后，每个 pod 会新增一个 debug 容器，可以在容器中执行命令进行调试，由于新增了容器，正在运行的 pod 会滚动重启（服务不会中断），且会导致 pod 的资源占用增加，建议在调试完成后关闭调试模式，以免影响正常的服务',
        'zh-TW':
            '開啟調試模式後，每個 pod 會新增一個 debug 容器，可以在容器中執行命令進行調試，由於新增了容器，正在運行的 pod 會滾動重啟（服務不會中斷），且會導致 pod 的資源占用增加，建議在調試完成後關閉調試模式，以免影響正常的服務',
        'ja': 'デバッグモードを有効にすると、各 pod に debug コンテナが追加され、コンテナ内でコマンドを実行してデバッグできます。コンテナが追加されるため、実行中の pod はローリングリスタートされます（サービスは中断されません）。また、pod のリソース使用量が増加するため、デバッグが完了したらデバッグモードを無効にしてください（正常なサービスに影響を与えないようにするためです）',
        'ko': '디버그 모드를 활성화하면 각 pod에 debug 컨테이너가 추가되어 컨테이너 내에서 명령을 실행하여 디버그할 수 있습니다. 컨테이너가 추가되기 때문에 실행 중인 pod는 롤링 리스타트됩니다(서비스는 중단되지 않습니다). 또한 pod의 리소스 사용량이 증가하므로 디버그가 완료되면 디버그 모드를 비활성화하여 정상 서비스에 영향을 주지 않도록 하십시오',
        'vi': 'Khi bật chế độ gỡ lỗi, mỗi pod sẽ có thêm một container gỡ lỗi, bạn có thể thực thi các lệnh trong container để gỡ lỗi. Vì thêm container nên pod đang chạy sẽ được khởi động lại (dịch vụ không bị gián đoạn), và sẽ tăng tải tài nguyên của pod, nên sau khi gỡ lỗi xong, bạn nên tắt chế độ gỡ lỗi để tránh ảnh hưởng đến dịch vụ bình thường',
        'en': 'After enabling debug mode, a debug container will be added to each pod, you can execute commands in the container to debug, because the container is added, the running pod will be rolling restarted (the service will not be interrupted), and the resource usage of the pod will increase, it is recommended to turn off the debug mode after debugging is completed to avoid affecting the normal service',
    },
    'enable stealing traffic debug mode': {
        'en': 'Enable stealing traffic debug mode',
        'zh-CN': '开启偷取流量的调试模式',
        'zh-TW': '開啟偷取流量的調試模式',
        'ja': 'トラフィックを盗むデバッグモードを有効にする',
        'ko': '트래픽을 훔치는 디버그 모드를 활성화합니다',
        'vi': 'Bật chế độ gỡ lỗi trộm gói tin',
    },
    'enable stealing traffic debug mode desc': {
        'zh-CN':
            '开启偷取流量的调试模式后，此组件（API Server 或 Runner）会在现有的 pod 基础上新增一个 debug pod，用于调试。由于是新增 pod，正在运行中的 pod 不会受任何影响，但是会增加整个组件的资源使用量，因此建议在调试完成后关闭调试模式。',
        'zh-TW':
            '開啟偷取流量的調試模式後，此組件（API Server 或 Runner）會在現有的 pod 基礎上新增一個 debug pod，用於調試。由於是新增 pod，正在運行中的 pod 不會受任何影響，但是會增加整個組件的資源使用量，因此建議在調試完成後關閉調試模式。',
        'ja': 'トラフィックを盗むデバッグモードを有効にすると、このコンポーネント（API ServerまたはRunner）は、既存のpodにdebug podを追加してデバッグします。podが追加されるため、実行中のpodには影響がありませんが、コンポーネントのリソース使用量が増加します。したがって、デバッグが完了したら、デバッグモードを無効にすることをお勧めします。',
        'ko': '트래픽을 훔치는 디버그 모드를 활성화하면 이 컴포넌트 (API Server 또는 Runner)는 기존 pod에 debug pod를 추가하여 디버깅합니다. pod가 추가되기 때문에 실행 중인 pod에는 영향이 없지만 컴포넌트의 리소스 사용량이 증가합니다. 따라서 디버깅이 완료된 후 디버그 모드를 비활성화하는 것이 좋습니다.',
        'vi': 'Bật chế độ gỡ lỗi trộm gói tin sẽ thêm một debug pod vào các pod hiện có, bạn có thể thực thi các lệnh trong debug pod để gỡ lỗi, do là thêm pod nên pod đang chạy sẽ không bị ảnh hưởng, nhưng sẽ tăng tải tài nguyên của toàn bộ pod, do đó khuyến khích tắt chế độ gỡ lỗi sau khi gỡ lỗi xong để tránh ảnh hưởng đến dịch vụ bình thường.',
        'en': 'After enabling the debug mode of stealing traffic, this component (API Server or Runner) will add a debug pod to the existing pod for debugging. Since the pod is added, there is no impact on the running pod, but the resource usage of the component will increase. Therefore, it is recommended to disable the debug mode after debugging is completed.',
    },
    'enable debug pod receive production traffic': {
        'zh-CN': '允许 debug pod 接收生产环境的流量',
        'zh-TW': '允許 debug pod 接收生產環境的流量',
        'ja': 'debug podが本番環境のトラフィックを受信できるようにする',
        'ko': 'debug pod가 프로덕션 트래픽을 수신할 수 있도록합니다.',
        'vi': 'Cho phép debug pod nhận gói tin từ môi trường sản xuất',
        'en': 'Allow debug pod to receive production traffic',
    },
    'enable debug pod receive production traffic desc': {
        'zh-CN':
            '开启偷取流量的调试模式后，默认情况下生产环境中的流量不会发送到 debug pod 中，需要手动在 HTTP header 中增加 `X-Yatai-Debug: true` 才能让流量发送到 debug pod 中。开启此选项后，生产环境中的流量会自动发送到 debug pod 中，无需手动添加 HTTP header。',
        'en': 'After enabling the debug mode of stealing traffic, the production environment traffic will not be sent to the debug pod by default, and you need to manually add `X-Yatai-Debug: true` in the HTTP header to send the traffic to the debug pod. After enabling this option, the production environment traffic will be automatically sent to the debug pod without manually adding the HTTP header.',
        'zh-TW':
            '開啟偷取流量的調試模式後，預設情況下生產環境中的流量不會發送到 debug pod 中，需要手動在 HTTP header 中增加 `X-Yatai-Debug: true` 才能讓流量發送到 debug pod 中。開啟此選項後，生產環境中的流量會自動發送到 debug pod 中，無需手動添加 HTTP header。',
        'ja': 'トラフィックを盗むデバッグモードを有効にすると、デフォルトでは本番環境のトラフィックはdebug podに送信されません。HTTPヘッダーに`X-Yatai-Debug: true`を追加する必要があります。このオプションを有効にすると、本番環境のトラフィックは自動的にdebug podに送信され、HTTPヘッダーを手動で追加する必要はありません。',
        'ko': '트래픽을 훔치는 디버그 모드를 활성화하면 기본적으로 프로덕션 환경의 트래픽은 debug pod로 전송되지 않습니다. HTTP 헤더에 `X-Yatai-Debug: true`를 추가해야합니다.이 옵션을 활성화하면 프로덕션 환경의 트래픽이 자동으로 debug pod로 전송되며 HTTP 헤더를 수동으로 추가할 필요가 없습니다.',
        'vi': 'Sau khi bật chế độ gỡ lỗi của việc trộm gói tin, gói tin môi trường sản xuất sẽ không được gửi đến debug pod theo mặc định, bạn cần thêm `X-Yatai-Debug: true` vào HTTP header để gửi gói tin đến debug pod. Sau khi bật tùy chọn này, gói tin môi trường sản xuất sẽ được tự động gửi đến debug pod mà không cần thêm HTTP header.',
    },
    'this is a production pod': {
        'zh-CN': '这是一个生产环境的 pod',
        'zh-TW': '這是一個生產環境的 pod',
        'ja': 'これは本番環境のpodです',
        'ko': '이것은 프로덕션 환경의 pod입니다.',
        'vi': 'Đây là một pod môi trường sản xuất',
        'en': 'This is a production pod',
    },
    'this is a debug pod': {
        'zh-CN': '这是一个调试环境的 pod',
        'zh-TW': '這是一個調試環境的 pod',
        'ja': 'これはデバッグ環境のpodです',
        'ko': '이것은 디버그 환경의 pod입니다.',
        'vi': 'Đây là một pod môi trường gỡ lỗi',
        'en': 'This is a debug pod',
    },
    'debug traffic': {
        'zh-CN': '调试流量',
        'zh-TW': '調試流量',
        'ja': 'デバッグトラフィック',
        'ko': '디버그 트래픽',
        'vi': 'Gỡ lỗi gói tin',
        'en': 'Debug traffic',
    },
    'receive debug traffic desc': {
        'zh-CN': '只接收 HTTP header 中包含 `X-Yatai-Debug: true` 的调试流量',
        'zh-TW': '只接收 HTTP header 中包含 `X-Yatai-Debug: true` 的調試流量',
        'ja': 'HTTPヘッダーに `X-Yatai-Debug: true` を含むデバッグトラフィックのみを受信します',
        'ko': 'HTTP 헤더에 `X-Yatai-Debug: true` 를 포함하는 디버그 트래픽만 수신합니다.',
        'vi': 'Chỉ nhận gói tin gỡ lỗi có HTTP header chứa `X-Yatai-Debug: true`',
        'en': 'Only receive debug traffic with HTTP header containing `X-Yatai-Debug: true`',
    },
    'production traffic': {
        'zh-CN': '生产流量',
        'zh-TW': '生產流量',
        'ja': '本番環境のトラフィック',
        'ko': '프로덕션 트래픽',
        'vi': 'Gói tin môi trường sản xuất',
        'en': 'Production traffic',
    },
    'receive production traffic desc': {
        'zh-CN': '所有的生产环境的流量都可能会被接收',
        'zh-TW': '所有的生產環境的流量都可能會被接收',
        'ja': 'すべての本番環境のトラフィックが受信される可能性があります',
        'ko': '모든 프로덕션 환경의 트래픽이 수신될 수 있습니다.',
        'vi': 'Tất cả gói tin môi trường sản xuất đều có thể nhận được',
        'en': 'All production traffic may be received',
    },
    'contains debugger container': {
        'zh-CN': '包含调试容器',
        'zh-TW': '包含調試容器',
        'ja': 'デバッガーコンテナを含む',
        'ko': '디버거 컨테이너를 포함합니다.',
        'vi': 'Chứa container gỡ lỗi',
        'en': 'Contains debugger container',
    },
}

export const locales: { [key in keyof typeof locales0]: ILocaleItem } = locales0
